// src/components/Popup.js
import React from "react";

const Popup = ({ show, onClose, children }) => {
  if (!show || !children) {
    if (onClose) onClose();
    return null;
  }

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 z-999 chp-popup-zindex">
        <div className="p-4 relative modaldiv">
          <div>{children}</div>
        </div>
      </div>
    </>
  );
};

export default Popup;
