import React, { useState } from "react";
import sub from "../../chatbot-image/sub_down-2.png";
import enterprise from "../../chatbot-image/pricingenter11111111111113 1 (1).png";
import icon11 from "../../chatbot-image/das_awards.png";
import check from "../../chatbot-image/check1111111111111111.png";
import fdf from "../../chatbot-image/logo-airbus 1.png";
import logo122222222 from "../../chatbot-image/logo-clients-forbes 1.png";
import logo4141 from "../../chatbot-image/logo-clients-usc 1.png";
import logo3536 from "../../chatbot-image/logo-clients-nascar 1.png";
import check101012 from "../../chatbot-image/material-symbols_check777777777777777.png";
import Emailpage from "../Website/emailsection.js";
function Pricing() {
  const [Communication, setCommunication] = useState(false);
  const [Conversation, setConversation] = useState(false);
  const [Customization, setCustomization] = useState(false);
  const [Reporting, setReporting] = useState(false);
  const [services, setservices] = useState(false);
  const [Integrations, setIntegrations] = useState(false);
  const [permissions, setpermissions] = useState(false);
  const [Automation, setAutomation] = useState(false);
  const [Business, setBusiness] = useState(true);
  const [Free, setFree] = useState(true);
  const [Smallbusiness, setSmallbusiness] = useState(false);
  const [Mediumbusiness, setMediumbusiness] = useState(false);
  const [Largebusiness, setLargebusiness] = useState(false);
  const [Enterprise, setEnterprise] = useState(false);
  const [interactions, setinteractions] = useState(false);
  const [show, setshow] = useState(false);
  const [show2, setshow2] = useState(false);
  const [show3, setshow3] = useState(false);
  return (
    <>
      <div className="container-fluid bg-gradient-to-b from-[#D7E4E4] chat_page_all">
        <div className="row ">
          <section className="xl:mt-[100px]">
            <div className="container m-auto pricing-section">
              <div className="pt-10">
                <h1 className="text-center text-[#434343] font-[950] text-[20px] tracking-[1px] font-poppins">
                  Chat Pricing
                </h1>
              </div>
              <div className="flex mt-4">
                <div className="m-auto font-sans text-center">
                  <a className="mx-2 text-[#303030]" href="#">
                    7 or 30 days free trial
                  </a>
                  <span>/</span>
                  <a className="mx-2 text-[#303030]" href="#">
                    No credit card required
                  </a>
                  <span>/</span>
                  <a className="mx-2 text-[#303030]" href="#">
                    Upgrade & pay as you go
                  </a>
                  <span>/</span>
                  <a className="mx-2 text-[#303030]" href="#">
                    No contracts
                  </a>
                  <span>/</span>
                  <a className="mx-2 text-[#303030]" href="#">
                    No setup fee
                  </a>
                  <span>/</span>
                  <a className="mx-2 text-[#303030]" href="#">
                    Cancel any time
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="mt-10 container m-auto pricing-options">
              <div className="lg:flex tablet-screen-flex tablet-screen-container">
                <div className="lg:w-1/2 tablet-screen">
                  <div
                    className="lg:w-1/2 lg:ml-8 text-center h-20 bg-[#FF6800] pt-2 lg:mx-0 mx-1 rounded"
                    style={{ background: "#FF6800" }}
                  >
                    <button
                      className={
                        Business
                          ? "Business_btn bg-[#fff] font-[900] text-[#DF673A] font-poppins"
                          : "Enterprise_btn font-[900] text-[#fff] font-poppins"
                      }
                      onClick={() => {
                        setBusiness(true);
                        setEnterprise(false);
                      }}
                    >
                      Business
                    </button>
                    <button
                      className={
                        Enterprise
                          ? "Business_btn bg-[#fff] font-[900] text-[#DF673A] font-poppins"
                          : "Enterprise_btn font-[900] text-[#fff] font-poppins"
                      }
                      onClick={() => {
                        setBusiness(false);
                        setEnterprise(true);
                      }}
                    >
                      Enterprise
                    </button>
                  </div>
                </div>
                <div className="lg:w-1/2 pricing-chat-options tablet-screen-position">
                  <button className="Save_billing bg-[#fff] font-bold text-[#fff] lg:float-right font-poppins">
                    Save up to 20% with annual billing
                  </button>
                </div>
              </div>
              <div className="main-btn-border"></div>
            </div>
          </section>

          {Business == true ? (
            <>
              <secton>
                <div className="w-full container m-auto chit_one ">
                  <div className="lg:flex w-full tablet_screen_flex justify-center tablet_screen_ttt	">
                    <div className="row pricing_chat_w">
                      {/* Free Plan */}
                      <div
                        className={`pricing_chat mt-10 flex ${
                          Free ? "trail-page-active-color" : ""
                        }`}
                        onClick={() => {
                          setFree(true);
                          setSmallbusiness(false);
                          setMediumbusiness(false);
                          setLargebusiness(false);
                        }}
                      >
                        <div className="w-1/2 lg:pl-10 pl-4 flex">
                          <input
                            type="radio"
                            id="free"
                            name="business"
                            checked={Free}
                            onChange={() => {}}
                          />
                          <h1 className="pl-2 lg:pt-4 pt-2 text-[#373737] hover:text-[#fff] pt-[25px] text-[18px] font-bold text_101 font-family: 'Poppins', sans-serif;">
                            Free
                          </h1>
                        </div>
                        <div className="w-1/2 pl-10 pt-8 text-center float-right">
                          <h1 className="pl-2 text-[#373737]">
                            <div className="text-lg font-family: 'Poppins', sans-serif;">
                              <span className="text-[15px] ">&#36;</span>
                              <span className="text-xl font-bold text-[#4E4E4E] font-family: 'Poppins', sans-serif;">
                                0/
                              </span>
                              Agent
                            </div>
                          </h1>
                        </div>
                      </div>

                      {/* Small Business Plan */}
                      <div
                        className={`pricing_chat mt-5 flex ${
                          Smallbusiness ? "trail-page-active-color" : ""
                        }`}
                        onClick={() => {
                          setFree(false);
                          setSmallbusiness(true);
                          setMediumbusiness(false);
                          setLargebusiness(false);
                        }}
                      >
                        <div className="w-1/2 lg:pl-10 pl-4 flex">
                          <input
                            type="radio"
                            id="smallbusiness"
                            name="business"
                            checked={Smallbusiness}
                            onChange={() => {}}
                          />
                          <div>
                            <h1 className="pl-2 lg:pt-4 pt-2 flex lg:block text-[#373737] text-[18px] text_101 font-bold font-family: 'Poppins', sans-serif;">
                              Small
                              <span className="font-medium mx-1 font-family: 'Poppins', sans-serif;">
                                business
                              </span>
                            </h1>
                            <h1 className="text-xs lg:mx-2 mx-2 font-family: 'Poppins', sans-serif;">
                              An advanced plan with less limits and more
                              features
                            </h1>
                          </div>
                        </div>
                        <div className="w-1/2 pl-10 pt-8 text-center float-right">
                          <h1 className="pl-2 text-[#373737]">
                            <div className="text-lg font-family: 'Poppins', sans-serif;">
                              <span className="text-[15px] ">&#36;</span>
                              <span className="text-xl font-bold text-[#4E4E4E] font-family: 'Poppins', sans-serif;">
                                9/
                              </span>
                              Agent
                            </div>
                          </h1>
                        </div>
                      </div>

                      {/* Medium Business Plan */}
                      <div
                        className={`pricing_chat mt-5 flex ${
                          Mediumbusiness ? "trail-page-active-color" : ""
                        }`}
                        onClick={() => {
                          setFree(false);
                          setSmallbusiness(false);
                          setMediumbusiness(true);
                          setLargebusiness(false);
                        }}
                      >
                        <div className="w-1/2 lg:pl-10 pl-4 flex">
                          <input
                            type="radio"
                            id="mediumbusiness"
                            name="business"
                            checked={Mediumbusiness}
                            onChange={() => {}}
                          />
                          <div>
                            <h1 className="pl-2 lg:pt-4 pt-2 flex lg:block text-[#373737] text-[18px] font-bold text_101 font-family: 'Poppins', sans-serif;">
                              Medium
                              <span className="font-medium mx-1 font-family: 'Poppins', sans-serif;">
                                business
                              </span>
                            </h1>
                            <h1 className="text-xs lg:mx-2 mx-2 font-family: 'Poppins', sans-serif;">
                              An advanced plan with less limits and more
                              features
                            </h1>
                          </div>
                        </div>
                        <div className="w-1/2 pl-10 pt-8 text-center float-right">
                          <h1 className="pl-2 text-[#373737]">
                            <div className="text-lg font-family: 'Poppins', sans-serif;">
                              <span className="text-[15px] ">&#36;</span>
                              <span className="text-xl font-bold text-[#4E4E4E] font-family: 'Poppins', sans-serif;">
                                29/
                              </span>
                              Agent
                            </div>
                          </h1>
                        </div>
                      </div>

                      {/* Large Business Plan */}
                      <div
                        className={`pricing_chat mt-5 flex ${
                          Largebusiness ? "trail-page-active-color" : ""
                        }`}
                        onClick={() => {
                          setFree(false);
                          setSmallbusiness(false);
                          setMediumbusiness(false);
                          setLargebusiness(true);
                        }}
                      >
                        <div className="w-1/2 lg:pl-10 pl-4 flex">
                          <input
                            type="radio"
                            id="largebusiness"
                            name="business"
                            checked={Largebusiness}
                            onChange={() => {}}
                          />
                          <div>
                            <h1 className="pl-2 lg:pt-4 pt-2 flex lg:block text-[#373737] text-[18px] font-bold text_101 font-family: 'Poppins', sans-serif;">
                              Large
                              <span className="font-medium mx-1 font-family: 'Poppins', sans-serif;">
                                business
                              </span>
                            </h1>
                            <h1 className="text-xs lg:mx-2 mx-2 font-family: 'Poppins', sans-serif;">
                              An advanced plan with less limits and more
                              features
                            </h1>
                          </div>
                        </div>
                        <div className="w-1/2 pl-10 pt-8 text-center float-right">
                          <h1 className="pl-2 text-[#373737]">
                            <div className="text-lg font-family: 'Poppins', sans-serif;">
                              <span className="text-[15px] ">&#36;</span>
                              <span className="text-[20px] font-bold text-[#4E4E4E]">
                                39/
                              </span>
                              Agent
                            </div>
                          </h1>
                        </div>
                      </div>
                    </div>

                    {Free == true ? (
                      <div className="lg:pricing_monthly_billing tablet_screen mt-10 lg:full_sereen ">
                        <div className="border	border-[#ff680052]  px-10	">
                          <h1 className="my-5 text-[#444444]  font-family: 'Poppins', sans-serif;">
                            Per Month Billed Annuallyor $15 Monthly Billing
                          </h1>
                          <div className="border-b border-dashed border-[#A6A6A6] mb-4"></div>
                          <form>
                            <div className="lg:px-5">
                              <div className="flex pl-2 my-2">
                                <div className="">
                                  <img src={check101012} />
                                </div>
                                <h1 className="px-4 text-[#3D3D3D] font-family: 'Poppins', sans-serif;">
                                  3 Email accounts
                                </h1>
                              </div>
                              <div className="flex pl-2 my-2">
                                <div className="">
                                  <img src={check101012} />
                                </div>
                                <h1 className="px-4 text-[#3D3D3D]  font-family: 'Poppins', sans-serif;">
                                  2 Live chat buttons
                                </h1>
                              </div>
                              <div className="flex pl-2 my-2">
                                <div className="">
                                  <img src={check101012} />
                                </div>
                                <h1 className="px-4 text-[#3D3D3D]  font-family: 'Poppins', sans-serif;">
                                  10 Departments
                                </h1>
                              </div>
                              <div className="flex pl-2 my-2">
                                <div className="">
                                  <img src={check101012} />
                                </div>
                                <h1 className="px-4 text-[#3D3D3D]  font-family: 'Poppins', sans-serif;">
                                  1 Knowledge base
                                </h1>
                              </div>
                              <div className="flex pl-2 my-2">
                                <div className="">
                                  <img src={check101012} />
                                </div>
                                <h1 className="px-4 text-[#3D3D3D]  font-family: 'Poppins', sans-serif;">
                                  50 Event rules
                                </h1>
                              </div>
                              <div className="flex pl-2 my-2">
                                <div className="">
                                  <img src={check101012} />
                                </div>
                                <h1 className="px-4 text-[#3D3D3D]  font-family: 'Poppins', sans-serif;">
                                  Customer service
                                </h1>
                              </div>
                              <div className="flex pl-2 my-2">
                                <div className="">
                                  <img src={check101012} />
                                </div>

                                <h1 className="px-4 text-[#3D3D3D]  font-family: 'Poppins', sans-serif;">
                                  White Glove Setup
                                </h1>
                              </div>
                              <div className="border-b border-dashed border-[#A6A6A6] mt-6"></div>
                              <div className="text-center text-[#303030] my-5 font-family: 'Poppins', sans-serif;">
                                <h1>7 or 30 days free trial </h1>
                              </div>
                            </div>
                            <div className="text-center border-2 hover:bg-[#008596] cursor-pointer	  p-2 bg-[#ff6800] text-[#fff] font-family: 'Poppins', sans-serif; ana-custom-colorstyle">
                              <button>Get Started</button>
                            </div>
                            <div className="text-center text-[#303030] mb-5 mt-2 font-family: 'Poppins', sans-serif;">
                              <h1>No credit card required</h1>
                            </div>
                          </form>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {Smallbusiness == true ? (
                      <>
                        <div className="lg:pricing_monthly_billing tablet_screen mt-10 lg:full_sereen ">
                          <div className="border	border-[#ff680052]  px-10	">
                            <h1 className="my-5 font-family: 'Poppins', sans-serif;">
                              Per Month Billed Annuallyor $15 Monthly Billing
                            </h1>
                            <div className="border-b border-dashed border-[#A6A6A6] mb-4"></div>
                            <form>
                              <div className="lg:px-10">
                                <div className="flex pl-2 my-2">
                                  <div className="">
                                    <img src={check101012} />
                                  </div>
                                  <h1 className="px-4 font-family: 'Poppins', sans-serif;">
                                    3 Email accounts
                                  </h1>
                                </div>
                                <div className="flex pl-2 my-2">
                                  <div className="">
                                    <img src={check101012} />
                                  </div>
                                  <h1 className="px-4 font-family: 'Poppins', sans-serif;">
                                    2 Live chat buttons
                                  </h1>
                                </div>
                                <div className="flex pl-2 my-2">
                                  <div className="">
                                    <img src={check101012} />
                                  </div>
                                  <h1 className="px-4 font-family: 'Poppins', sans-serif;">
                                    10 Departments
                                  </h1>
                                </div>
                                <div className="flex pl-2 my-2">
                                  <div className="">
                                    <img src={check101012} />
                                  </div>
                                  <h1 className="px-4 font-family: 'Poppins', sans-serif;">
                                    1 Knowledge base
                                  </h1>
                                </div>
                                <div className="flex pl-2 my-2">
                                  <div className="">
                                    <img src={check101012} />
                                  </div>
                                  <h1 className="px-4 font-family: 'Poppins', sans-serif;">
                                    50 Event rules
                                  </h1>
                                </div>
                                <div className="flex pl-2 my-2">
                                  <div className="">
                                    <img src={check101012} />
                                  </div>
                                  <h1 className="px-4 font-family: 'Poppins', sans-serif;">
                                    Customer service
                                  </h1>
                                </div>
                                <div className="flex pl-2 my-2">
                                  <div className="">
                                    <img src={check101012} />
                                  </div>

                                  <h1 className="px-4 font-family: 'Poppins', sans-serif;">
                                    White Glove Setup
                                  </h1>
                                </div>
                                <div className="border-b border-dashed border-[#A6A6A6] mt-6"></div>
                                <div className="text-center my-5 font-family: 'Poppins', sans-serif;">
                                  <h1>7 or 30 days free trial </h1>
                                </div>
                              </div>
                              <div className="text-center border-2 hover:bg-[#008596] cursor-pointer	font-family: 'Poppins', sans-serif;  p-2 bg-[#ff6800] text-[#fff]  ana-custom-colorstyle">
                                <button>Get Started</button>
                              </div>
                              <div className="text-center mb-5 mt-2 font-family: 'Poppins', sans-serif;">
                                <h1>No credit card required</h1>
                              </div>
                            </form>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {Mediumbusiness == true ? (
                      <>
                        <div className="lg:pricing_monthly_billing tablet_screen mt-10 lg:full_sereen ">
                          <div className="border	border-[#ff680052]  px-10	 font-family: 'Poppins', sans-serif;">
                            <h1 className="my-5 font-family: 'Poppins', sans-serif;">
                              Per Month Billed Annuallyor $15 Monthly Billing
                            </h1>
                            <div className="border-b border-dashed border-[#A6A6A6] mb-4"></div>
                            <form>
                              <div className="lg:px-10">
                                <div className="flex pl-2 my-2">
                                  <div className="">
                                    <img src={check101012} />
                                  </div>
                                  <h1 className="px-4 font-family: 'Poppins', sans-serif;">
                                    3 Email accounts
                                  </h1>
                                </div>
                                <div className="flex pl-2 my-2">
                                  <div className="">
                                    <img src={check101012} />
                                  </div>
                                  <h1 className="px-4 font-family: 'Poppins', sans-serif;">
                                    2 Live chat buttons
                                  </h1>
                                </div>
                                <div className="flex pl-2 my-2">
                                  <div className="">
                                    <img src={check101012} />
                                  </div>
                                  <h1 className="px-4 font-family: 'Poppins', sans-serif;">
                                    10 Departments
                                  </h1>
                                </div>
                                <div className="flex pl-2 my-2">
                                  <div className="">
                                    <img src={check101012} />
                                  </div>
                                  <h1 className="px-4 font-family: 'Poppins', sans-serif;">
                                    1 Knowledge base
                                  </h1>
                                </div>
                                <div className="flex pl-2 my-2">
                                  <div className="">
                                    <img src={check101012} />
                                  </div>
                                  <h1 className="px-4 font-family: 'Poppins', sans-serif;">
                                    50 Event rules
                                  </h1>
                                </div>
                                <div className="flex pl-2 my-2">
                                  <div className="">
                                    <img src={check101012} />
                                  </div>
                                  <h1 className="px-4 font-family: 'Poppins', sans-serif;">
                                    Customer service
                                  </h1>
                                </div>
                                <div className="flex pl-2 my-2">
                                  <div className="">
                                    <img src={check101012} />
                                  </div>

                                  {/* <input
                                    className="color-[#DF673A]"
                                    type="checkbox"
                                    id=""
                                    name="fav_language"
                                    value=""
                                  /> */}
                                  <h1 className="px-4 font-family: 'Poppins', sans-serif;">
                                    White Glove Setup
                                  </h1>
                                </div>
                                <div className="border-b border-dashed border-[#A6A6A6] mt-6"></div>
                                <div className="text-center my-5 font-family: 'Poppins', sans-serif;">
                                  <h1>7 or 30 days free trial </h1>
                                </div>
                              </div>
                              <div className="text-center border-2 hover:bg-[#008596] cursor-pointer	font-family: 'Poppins', sans-serif;  p-2 bg-[#ff6800] text-[#fff] ana-custom-colorstyle">
                                <button>Get Started</button>
                              </div>
                              <div className="text-center mb-5 mt-2 font-family: 'Poppins', sans-serif;">
                                <h1>No credit card required</h1>
                              </div>
                            </form>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {Largebusiness == true ? (
                      <>
                        xcbxbx
                        <div className="lg:pricing_monthly_billing tablet_screen mt-10 lg:full_sereen ">
                          <div className="border	border-[#ff680052]  px-10	">
                            <h1 className="my-5 font-family: 'Poppins', sans-serif;">
                              Per Month Billed Annuallyor $15 Monthly Billing
                            </h1>
                            <div className="border-b border-dashed border-[#A6A6A6] mb-4"></div>
                            <form>
                              <div className="lg:px-10">
                                <div className="flex pl-2 my-2">
                                  <div className="">
                                    <img src={check101012} />
                                  </div>
                                  <h1 className="px-4 font-family: 'Poppins', sans-serif;">
                                    3 Email accounts
                                  </h1>
                                </div>
                                <div className="flex pl-2 my-2">
                                  <div className="">
                                    <img src={check101012} />
                                  </div>
                                  <h1 className="px-4 font-family: 'Poppins', sans-serif;">
                                    2 Live chat buttons
                                  </h1>
                                </div>
                                <div className="flex pl-2 my-2">
                                  <div className="">
                                    <img src={check101012} />
                                  </div>
                                  <h1 className="px-4 font-family: 'Poppins', sans-serif;">
                                    10 Departments
                                  </h1>
                                </div>
                                <div className="flex pl-2 my-2">
                                  <div className="">
                                    <img src={check101012} />
                                  </div>
                                  <h1 className="px-4 font-family: 'Poppins', sans-serif;">
                                    1 Knowledge base
                                  </h1>
                                </div>
                                <div className="flex pl-2 my-2">
                                  <div className="">
                                    <img src={check101012} />
                                  </div>
                                  <h1 className="px-4 font-family: 'Poppins', sans-serif;">
                                    50 Event rules
                                  </h1>
                                </div>
                                <div className="flex pl-2 my-2">
                                  <div className="">
                                    <img src={check101012} />
                                  </div>
                                  <h1 className="px-4 font-family: 'Poppins', sans-serif;">
                                    Customer service
                                  </h1>
                                </div>
                                <div className="flex pl-2 my-2">
                                  <div className="">
                                    <img src={check101012} />
                                  </div>

                                  <h1 className="px-4 font-family: 'Poppins', sans-serif;">
                                    White Glove Setup
                                  </h1>
                                </div>
                                <div className="border-b border-dashed border-[#A6A6A6] mt-6"></div>
                                <div className="text-center my-5 font-family: 'Poppins', sans-serif;">
                                  <h1>7 or 30 days free trial </h1>
                                </div>
                              </div>
                              <div className="text-center border-2 hover:bg-[#008596] cursor-pointer	font-family: 'Poppins', sans-serif;  p-2 bg-[#ff6800] text-[#fff]  ana-custom-colorstyle">
                                <button>Get Started</button>
                              </div>
                              <div className="text-center mb-5 mt-2 font-family: 'Poppins', sans-serif;">
                                <h1>No credit card required</h1>
                              </div>
                            </form>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </secton>
              <section className=" ">
                <div className="container m-auto chit_one ">
                  <div className="w-full lg:flex justify-center my-10 tablet_screen_ttt  lg:px-10 lg:grid lg:grid-cols-4 lg:gap-4 md:grid md:grid-cols-2 md:gap-4">
                    <div className=" border-2	m-1 py-5 pb-0 flex card_enrety lg:justify-around border-dashed border-[#4C4C4C] md:h-[120px] lg:h-h-40 lg:w-full">
                      <div className="pt-2 lg:w-20">
                        <label
                          className="toggleSwitch xlarge lg:w-[60px]"
                          onClick=""
                        >
                          <input
                            type="checkbox"
                            className="wy cursor-pointer	"
                            onClick={() => {
                              setshow(!show);
                            }}
                          />
                          <span>
                            <span className=" x_setting text-[#4C4C4C] font-family: 'Poppins', sans-serif;">
                              X
                            </span>
                            <span></span>
                          </span>
                          <a></a>
                        </label>
                      </div>
                      <div className="lg:ml-5 ">
                        <h1 className="text-[#474747] font-family: 'Poppins', sans-serif; ">
                          Automation add-on
                          <span className="text-[#FF6800] font-[950]">
                            +$17/mo
                          </span>
                        </h1>

                        {show == true ? (
                          <>
                            <div
                              className="border-[#A8A8A8] py-2 	"
                              id="SectionName"
                            >
                              <select className="bg-[#fff] rounded border-[1px] pl-2 text-[#616161]">
                                <option>0-24</option>
                                <option>25-50</option>
                                <option>51-75</option>
                                <option>76-100</option>
                              </select>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <h1 className="text-[#5A5A5A] text-xs pt-2 font-family: 'Poppins', sans-serif;">
                          Monthly automated interactions
                        </h1>
                      </div>
                    </div>
                    <div className=" border-2	m-1 py-5 pb-0 flex card_enrety lg:justify-around border-dashed border-[#4C4C4C] md:h-[120px] lg:h-40 lg:w-full ">
                      <div
                        className="pt-2 lg:w-20 "
                        onClick={() => {
                          setshow2(!show2);
                        }}
                      >
                        <label className="toggleSwitch xlarge lg:w-[60px]">
                          <input
                            className="wy cursor-pointer	 "
                            type="checkbox"
                            //onClick={() => {
                            //   setshow2(!show2);
                            // }}
                          />
                          <span>
                            <span
                              className=" x_setting text-[#4C4C4C]"
                              onClick={() => {
                                setshow2(!show2);
                              }}
                            >
                              X
                            </span>
                            <span></span>
                          </span>
                          <a></a>
                        </label>
                      </div>
                      <div className="lg:ml-5 ">
                        <h1 className="text-[#474747] font-family: 'Poppins', sans-serif; ">
                          Automation add-on
                          <span className="text-[#FF6800] font-[950]">
                            +$17/mo
                          </span>
                        </h1>

                        {show2 == true ? (
                          <>
                            <div
                              className="border-[#A8A8A8] py-2 	"
                              id="SectionName"
                            >
                              <select className="bg-[#fff] rounded border-[1px] pl-2 text-[#616161]">
                                <option>0-24</option>
                                <option>25-50</option>
                                <option>51-75</option>
                                <option>76-100</option>
                              </select>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <h1 className="text-[#5A5A5A] text-xs pt-2 font-family: 'Poppins', sans-serif;">
                          Monthly automated interactions
                        </h1>
                      </div>
                    </div>

                    <div className=" border-2	m-1 py-5 pb-0 flex lg:justify-around  card_enrety border-dashed border-[#4C4C4C]  md:h-[120px] lg:h-40 lg:w-full">
                      <div className="pt-2 lg:w-20">
                        <label
                          className="toggleSwitch xlarge lg:w-[60px]"
                          onClick=""
                        >
                          <input
                            className="wy cursor-pointer	"
                            type="checkbox"
                            onClick={() => {
                              setshow3(!show3);
                            }}
                          />
                          <span>
                            <span className=" x_setting text-[#4C4C4C]">X</span>
                            <span></span>
                          </span>
                          <a></a>
                        </label>
                      </div>
                      <div className="lg:ml-5 ">
                        <h1 className="text-[#474747] font-family: 'Poppins', sans-serif; ">
                          Automation add-on
                          <span className="text-[#FF6800] font-[950]">
                            +$17/mo
                          </span>
                        </h1>

                        {show3 == true ? (
                          <>
                            <div
                              className="border-[#A8A8A8] py-2 	"
                              id="SectionName"
                            >
                              <select className="bg-[#fff] rounded border-[1px] pl-2 text-[#616161]">
                                <option>0-24</option>
                                <option>25-50</option>
                                <option>51-75</option>
                                <option>76-100</option>
                              </select>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <h1 className="text-[#5A5A5A] text-xs pt-2 font-family: 'Poppins', sans-serif;">
                          Monthly automated interactions
                        </h1>
                      </div>
                    </div>
                    <div className=" border-2 m-1 py-5 text-center bg-[#FF6800] Business_card-4 border-dashed border-[#fff] h-[120px] lg:h-40 lg:w-full">
                      <div className="">
                        <h1 className="text-white tracking-[1px] ">
                          <span className="text-white font-bold tracking-[1px] font-family: 'Poppins', sans-serif;	">
                            $300{" "}
                          </span>
                          /month
                        </h1>
                        <div className="bg-[#fff] mt-6 mx-4 ">
                          <button className="bg-[#fff] border-[#fff] w-full py-2 text-[#373737] font-family: 'Poppins', sans-serif;">
                            Start a free trial
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div>
                <section className="compareheadingdiv">
                  <div className="container m-auto chit_one ">
                    <div
                      className="text-center bg-[#FF6800]"
                      style={{ background: "#FF6800" }}
                    >
                      <div className="pt-8 ">
                        <h1 className="font-[950] text-xl text-white tracking-[1px]	font-family: 'Poppins', sans-serif; ">
                          Compare Our Plans In Detail
                        </h1>
                        <h2 className="text-white pt-2 text-[13px]  font-family: 'Poppins', sans-serif;">
                          Check Out Our Plan Comparisons And Select The Best One
                          For You for you
                        </h2>
                      </div>
                      <div className=" mt-10 lg:flex justify-end mr-2">
                        <div className="lg:flex md:flex lg:pl-1/4 md:justify-center md:ml-[0px] md:pl-[0%] ml-[4px]	sm:ml-[0px]">
                          <div
                            className=" chat_Ai_responcive_k responcive_set_w lg:w-[191px] xl:w-60 md:w-40   text-center py-5 bg-[#fff] font-family: 'Poppins', sans-serif;  border-b border-b-[#A0A0A0] lg:border-r-1 border-black"
                            style={{ background: "#fff" }}
                          >
                            <h1 className="  text-[#303030]  font-[400]  text-[16px] font-family: 'Poppins', sans-serif;">
                              Free
                            </h1>
                            <button>
                              <span className="text-xs">&#36;</span>
                              <span className="text-3xl  font-bold mx-1 text-[#303030] font-family: 'Poppins', sans-serif;">
                                0/-
                              </span>
                            </button>
                          </div>
                          <div
                            className="chat_Ai_responcive_k  responcive_set_w lg:w-[191px] xl:w-60 md:w-40  md:border-l-2 text-center py-5 bg-[#fff] border-b border-b-[#A0A0A0] lg:border-r-1 border-black"
                            style={{ background: "#fff" }}
                          >
                            <h1 className="text-base font-[400]  text-[16px] text-[#303030]">
                              Small Business
                            </h1>
                            <button>
                              <span className="text-xs">&#36;</span>
                              <span
                                className="text-3xl font-bold mx-1 text-[#303030]"
                                style={{ color: "#303030" }}
                              >
                                9/
                              </span>
                              <span className="text-3xl text-[#303030]">
                                Agent
                              </span>
                            </button>
                          </div>
                          <div
                            className=" chat_Ai_responcive_k responcive_set_w lg:w-[191px]	xl:w-60 md:w-40  md:border-l-2 text-center py-5 bg-[#fff] border-b-[#A0A0A0] border-b lg:border-r-1 border-black"
                            style={{ background: "#fff" }}
                          >
                            <h1 className="text-base   font-[400]  text-[16px] text-[#303030]">
                              Medium Business
                            </h1>
                            <button style={{ color: "#303030" }}>
                              <span className="text-xs">&#36;</span>
                              <span className="text-3xl font-bold mx-1 text-[#303030]">
                                29/
                              </span>
                              <span className="text-3xl text-[#303030]">
                                Agent
                              </span>
                            </button>
                          </div>
                          <div
                            className=" chat_Ai_responcive_k responcive_set_w lg:w-[191px]	xl:w-60 md:w-40   md:border-l-2 text-center py-5 bg-[#fff] border-b border-b-[#A0A0A0] lg:border-r-1 border-black"
                            style={{ background: "#fff" }}
                          >
                            <h1 className="  font-[400]  text-[20px] text-[#303030] font-family: 'Poppins', sans-serif;">
                              Large business
                            </h1>
                            <button style={{ color: "#303030" }}>
                              <span className="text-xs">&#36;</span>
                              <span className="text-3xl font-bold mx-1 text-[#303030]">
                                39/
                              </span>
                              <span className="text-3xl text-[#303030]">
                                Agent
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  <div className="container m-auto chit_one ">
                    <div className="flex  justify-between py-4 px-7 bg-[#EDEDED] border-[1px]  border-[#A0A0A0] business-accordion-section  ">
                      <p className="text-[18px] text-[#464646]">
                        Communication channels
                      </p>
                      <p className="w-[16px] h-[20px]">
                        <img
                          className="comunicationimg"
                          src={sub}
                          onClick={() =>
                            setCommunication((Communication) => !Communication)
                          }
                        />
                      </p>
                    </div>
                    {Communication == true ? (
                      <div className="">
                        <table className=" px-2 w-full">
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Customer Portal
                            </td>
                            <td className="business-accordion-content-section">
                              1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Contact forms – Contact us buttons
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Contact forms – In page
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Slack notifications
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Customer form
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Email ticketing – Incoming email accounts
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Email ticketing – Outgoing email accounts
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Live chat button
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Proactive chat invitations
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Call center
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Feedback buttons
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Viber
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Twitter
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Facebook & Instagram
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              WhatsApp
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Video call functionality
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                        </table>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </section>
                <section className="">
                  <div className="container m-auto chit_one ">
                    <div className="flex  justify-between py-4 px-7 bg-[#EDEDED] border-[1px]  border-[#A0A0A0] business-accordion-section business-accordion-section">
                      <p className="text-[18px] text-[#464646]">
                        {" "}
                        Conversation management
                      </p>
                      <p className="w-[16px] h-[20px]">
                        <img
                          src={sub}
                          onClick={() =>
                            setConversation((Conversation) => !Conversation)
                          }
                        />
                      </p>
                    </div>
                    {Conversation == true ? (
                      <div className="">
                        <table className="px-2 w-full">
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket history
                            </td>
                            <td className="w-[225px] border-r-[1px] border-[#A0A0A0] bg-[#fff] text-center">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Merge tickets
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom ticket fields
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Split tickets
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Internal tickets
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Attachments
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket mass actions
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              To Solve Algorithm
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket satisfaction survey
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom tags
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom ticket filters
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Agent notes to tickets
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Real-time dashboards
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket transfers
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              WYSIWYG editor
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Multiple ticket tabs
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Business hours
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Departments
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Roles in departments
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Canned messages
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Contacts & Groups
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Predefined answers
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Chat transcripts
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              File sharing over chat
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Mobile chat window
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Chat welcome message setting
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Real-time visitors monitor
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Chat Satisfaction Surveys
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Time Tracking
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Call Queueing
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              IVR
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              In call recordings
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Call routing & transfers
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom roles
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                        </table>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </section>
                <section>
                  <div className="container m-auto chit_one">
                    <div className="flex  justify-between py-4 px-7 bg-[#EDEDED] border-[1px]  border-[#A0A0A0] business-accordion-section business-accordion-section">
                      <p className="text-[18px] text-[#464646]">
                        Customization
                      </p>
                      <p className="w-[16px] h-[20px]">
                        <img
                          src={sub}
                          onClick={() =>
                            setCustomization((Customization) => !Customization)
                          }
                        />
                      </p>
                    </div>
                    {Customization == true ? (
                      <div className=" bg-[#FFF7F4]">
                        <table className=" px-2 w-full bg-[#FFF7F4]">
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Customer Portal
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Contact forms – Contact us buttons
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Contact forms – In page
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Slack notifications
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Customer form
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Email ticketing – Incoming email accounts
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Email ticketing – Outgoing email accounts
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Live chat button
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Proactive chat invitations
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Call center
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Feedback buttons
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Viber
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Twitter
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Facebook & Instagram
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              WhatsApp
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Video call functionality
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                        </table>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </section>
                <section>
                  <div className="container m-auto chit_one">
                    <div className="flex  justify-between py-4 px-7 bg-[#EDEDED] border-[1px]  border-[#A0A0A0] business-accordion-section business-accordion-section">
                      <p className="text-[18px] text-[#464646]">Reporting</p>
                      <p className="w-[16px] h-[20px]">
                        <img
                          src={sub}
                          onClick={() =>
                            setReporting((Reporting) => !Reporting)
                          }
                        />
                      </p>
                    </div>
                    {Reporting == true ? (
                      <div className="bg-[#FFF7F4]">
                        <table className="px-2 w-full ">
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Customer Portal
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Contact forms – Contact us buttons
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Contact forms – In page
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Slack notifications
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Customer form
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Email ticketing – Incoming email accounts
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Email ticketing – Outgoing email accounts
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Live chat button
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Proactive chat invitations
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Call center
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Feedback buttons
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Viber
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Twitter
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Facebook & Instagram
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              WhatsApp
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Video call functionality
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                        </table>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </section>
                <section>
                  <div className="container m-auto chit_one">
                    <div className="flex  justify-between py-4 px-7 bg-[#EDEDED] border-[1px]  border-[#A0A0A0] business-accordion-section business-accordion-section">
                      <p className="text-[18px] text-[#464646]">Integrations</p>
                      <p className="w-[16px] h-[20px]">
                        <img
                          src={sub}
                          onClick={() =>
                            setIntegrations((Integrations) => !Integrations)
                          }
                        />
                      </p>
                    </div>
                    {Integrations == true ? (
                      <div className=" bg-[#FFF7F4] ">
                        <table className="px-2 w-full ">
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Customer Portal
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Contact forms – Contact us buttons
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                        </table>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </section>
                <section>
                  <div className="container m-auto chit_one">
                    <div className="flex  justify-between py-4 px-7 bg-[#EDEDED] border-[1px]  border-[#A0A0A0] business-accordion-section">
                      <p className="text-[18px] text-[#464646]">
                        Support and services
                      </p>
                      <p className="w-[16px] h-[20px]">
                        <img
                          src={sub}
                          onClick={() => setservices((services) => !services)}
                        />
                      </p>
                    </div>
                    {services == true ? (
                      <div className=" bg-[#FFF7F4]">
                        <table className="px-2 w-full ">
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Customer Portal
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Contact forms – Contact us buttons
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                        </table>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </section>
                <section>
                  <div className="container m-auto  chit_one">
                    <div className="flex  justify-between py-4 px-7 bg-[#EDEDED] border-[1px]  border-[#A0A0A0] business-accordion-section">
                      <p className="text-[18px] text-[#464646]">
                        Security and permissions
                      </p>
                      <p className="w-[16px] h-[20px]">
                        <img
                          src={sub}
                          onClick={() =>
                            setpermissions((permissions) => !permissions)
                          }
                        />
                      </p>
                    </div>
                    {permissions == true ? (
                      <div className=" bg-[#FFF7F4]">
                        <table className="px-2 w-full ">
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Customer Portal
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Contact forms – Contact us buttons
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                        </table>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </section>
                <section>
                  <div className="container m-auto chit_one">
                    <div className="flex  justify-between py-4 px-7 bg-[#EDEDED] border-[1px]  border-[#A0A0A0] business-accordion-section">
                      <p className="text-[18px] text-[#464646]">Automation</p>
                      <p className="w-[16px] h-[20px]">
                        <img
                          src={sub}
                          onClick={() =>
                            setAutomation((Automation) => !Automation)
                          }
                        />
                      </p>
                    </div>
                    {Automation == true ? (
                      <div className=" bg-[#FFF7F4]">
                        <table className="px-2 w-full ">
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Customer Portal
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Contact forms – Contact us buttons
                            </td>
                            <td className="business-accordion-content-section">
                              section 1
                            </td>
                            <td className="business-accordion-content-section">
                              section 2
                            </td>
                            <td className="business-accordion-content-section">
                              section 3
                            </td>
                            <td className="business-accordion-content-section">
                              section 4
                            </td>
                          </tr>
                        </table>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </section>
              </div>
            </>
          ) : (
            ""
          )}
          {Enterprise == true ? (
            <>
              <section>
                <div className="container m-auto chit_one">
                  <div className="w-full lg:flex p-3 tablet_screen_flex ">
                    <div className="col lg:w-1/2 mt-10 lg:ml-[20px] enterprice">
                      <div className="lg:flex w-full">
                        <div className="lg:w-1/2">
                          <p>
                            <img src={enterprise} alt="" width="" height="" />
                          </p>
                        </div>
                        <div className="lg:w-1/2">
                          <div className=" w-[]  mt-8">
                            <div className="text-[#444444]  ">
                              <div className="border-[1px] bg-[#FEEBE4] p-1 rounded-3xl text-[#2A2A2A] flex ">
                                <div className="m-auto flex">
                                  <div className="">
                                    <img
                                      src={icon11}
                                      alt=""
                                      height="100px"
                                      width=""
                                    />
                                  </div>
                                  <div>
                                    <p className="text-[#2A2A2A] text-[12px]">
                                      Extra support & exclusive services
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <h1 className="font-bold text-xl mt-5">
                                Enterprise
                              </h1>
                              <div className="text-[#414141] w-[325px] ">
                                <p className=" text-[#303030] text-[14px] mt-2">
                                  A full-scale business solution with extra
                                  support & exclusive services
                                </p>
                                <p className="text-[#303030] mt-2 text-xl">
                                  $
                                  <span className="font-extrabold text-xl">
                                    69/
                                  </span>
                                  Agent
                                </p>
                              </div>
                              <div className="text-[14px]">
                                <p>
                                  Per Month Billed Annualyor $85 Month-to-Month
                                </p>
                              </div>
                              <div className="bg-[#FF6800] my-2 text-white font-bold text-center ana-custom-colorstyle">
                                <button className="w-[lg:325px] h-11 font-bold">
                                  Book Demo Call
                                </button>
                              </div>
                              <div className="bg-[#FF6800] my-2 text-white font-bold text-center ana-custom-colorstyle">
                                <button className="w-[lg:325px] h-11 font-bold">
                                  Start Trial
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div>
                          <h1 className="font-[950]	 text-[#444444] text-[20px] pt-10">
                            Looking For Something Extra For Your Business?
                          </h1>
                          <p className="text-[#343434] text-[16px] pt-3">
                            There’s no need to look further. Chat Enterprise
                            plan is the right choice for you! In addition to all
                            the tools you know from the previous plans, you get
                            extra services such as priority customer support,
                            assisted integrations, a custom update schedule, and
                            more.Take a look at the full list of services and
                            find out how you can benefit from the Enterprise
                            plan.
                          </p>
                        </div>
                        <div className="pt-5">
                          <h1 className="font-[950] text-[#444444] text-[18px]">
                            You Are In A Good Company
                          </h1>
                          <div className="flex p-1 ">
                            <p>
                              <img
                                src={logo122222222}
                                className="px-2"
                                alt="logo-clients-forb"
                                height="17px"
                                width="80px"
                              />
                            </p>
                            <p>
                              <img
                                src={fdf}
                                className="px-2"
                                alt="logo-clients-airbus"
                                height="17px"
                                width="80px"
                              />
                            </p>
                            <p>
                              <img
                                src={logo3536}
                                className="px-2"
                                alt="logo-clients-nascar"
                                height="17px"
                                width="80px"
                              />
                            </p>
                            <p>
                              <img
                                src={logo4141}
                                className="px-2"
                                alt="logo-clients-usc.1"
                                height="17px"
                                width="70px"
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col lg:w-1/2 lg:m-5 lg:mr-0 enterprice_22 enterprice_22_position">
                      <div
                        className="lg:m-5 border-[2px] border-[#FF6800]"
                        style={{
                          margin: "1.25rem",
                          border: "2px solid #FF6800",
                        }}
                      >
                        <div className="row  p-6">
                          <div>
                            <h1 className="font-[900] text-[#444444] text-[20px]">
                              Enterprise plan includes
                            </h1>
                          </div>
                          <hr className="my-5 border-dashed	border-b-[0px] border-[2px]"></hr>
                          <div>
                            <div className="flex my-2">
                              <div className="">
                                <img src={check101012} />
                              </div>
                              <div className="mx-2">
                                <h1 className=" text-[#3D3D3D] text-[17px]">
                                  All features from the Large plan
                                </h1>
                                <p className="text-[#808080] text-[14px]">
                                  Get all LiveAgent’s Large features
                                </p>
                              </div>
                            </div>
                            <div className="flex my-2">
                              <div className="">
                                <img src={check101012} />
                              </div>
                              <div className="mx-2">
                                <h1 className=" text-[#3D3D3D] text-[17px]">
                                  Dedicated key account manager
                                </h1>
                                <p className="text-[#808080] text-[14px]">
                                  You’ll get a dedicated LiveAgent assistant
                                  (for Enterprise accounts with 10+ agents only)
                                </p>
                              </div>
                            </div>
                            <div className="flex my-2">
                              <div className="">
                                <img src={check101012} />
                              </div>
                              <div className="mx-2">
                                <h1 className=" text-[#3D3D3D] text-[17px]">
                                  Priority support
                                </h1>
                                <p className="text-[#808080] text-[14px]">
                                  We’ll prioritize your inquiries (for
                                  Enterprise accounts with 10+ agents only)
                                </p>
                              </div>
                            </div>
                            <div className="flex my-2">
                              <div className="">
                                <img src={check101012} />
                              </div>
                              <div className="mx-2">
                                <h1 className=" text-[#3D3D3D] text-[17px]">
                                  Assisted Whatsapp integration
                                </h1>
                                <p className="text-[#808080] text-[14px]">
                                  We’ll help integrate your WhatsApp for you
                                </p>
                              </div>
                            </div>
                            <div className="flex my-2">
                              <div className="">
                                <img src={check101012} />
                              </div>
                              <div className="mx-2">
                                <h1 className=" text-[#3D3D3D] text-[17px]">
                                  Custom billing setup
                                </h1>
                                <p className="text-[#808080] text-[14px]">
                                  We’ll adjust the billing to your preference
                                </p>
                              </div>
                            </div>
                            <div className="flex my-2">
                              <div className="">
                                <img src={check101012} />
                              </div>
                              <div className="mx-2">
                                <h1 className=" text-[#3D3D3D] text-[17px]">
                                  Scheduled release management
                                </h1>
                                <p className="text-[#808080] text-[14px]">
                                  Get a tailor made maintanance window (for
                                  Enterprise accounts with 10+ agents only)
                                </p>
                              </div>
                            </div>
                            <div className="flex my-2">
                              <div className="">
                                <img src={check101012} />
                              </div>
                              <div className="mx-2">
                                <h1 className=" text-[#3D3D3D] text-[17px]">
                                  Up to 40 custom roles
                                </h1>
                                <p className="text-[#808080] text-[14px]">
                                  Create custom roles for support agents in your
                                  organization
                                </p>
                              </div>
                            </div>
                            <div className="flex my-2">
                              <div className="">
                                <img src={check101012} />
                              </div>
                              <div className="mx-2">
                                <h1 className=" text-[#3D3D3D] text-[17px]">
                                  Up to 40 custom roles
                                </h1>
                                <p className="text-[#808080] text-[14px]">
                                  Create custom roles for support agents in your
                                  organization
                                </p>
                              </div>
                            </div>
                            <div className="flex my-2">
                              <div className="">
                                <img src={check101012} />
                              </div>
                              <div className="mx-2">
                                <h1 className=" text-[#3D3D3D] text-[17px]">
                                  Multileveled knowledge base
                                </h1>
                                <p className="text-[#808080] text-[14px]">
                                  Create multiple different knowledgebases on
                                  different domains with one LiveAgent account
                                </p>
                              </div>
                            </div>
                            <div className="flex my-2">
                              <div className="">
                                <img src={check101012} />
                              </div>
                              <div className="mx-2">
                                <h1 className=" text-[#3D3D3D] text-[17px]">
                                  Assisted channels integration
                                </h1>
                                <p className="text-[#808080] text-[14px]">
                                  Get help with communication channel setup (for
                                  Enterprise accounts with 10+ agents only)
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div className="container m-auto chit_one">
                  <div
                    className="text-center bg-[#FF6800]"
                    style={{ background: "#FF6800" }}
                  >
                    <div className="pt-8">
                      <h1 className="font-[950] text-xl text-white tracking-[1px] ">
                        Compare Our Plans In Detail
                      </h1>
                      <h2 className="text-white pt-2  text-[13px] ">
                        Check Out Our Plan Comparisons And Select The Best One
                        For You
                      </h2>
                    </div>
                    <div className=" mt-10 lg:flex">
                      <div className="lg:flex m-auto lg:mr-8">
                        <div className="lg:w-96 text-center py-5 bg-white border-b border-b-[#A0A0A0] lg:border-r-1 border-black ">
                          <h1 className=" text-[16px] text-[#303030] font-family: 'Poppins', sans-serif;">
                            Large business
                          </h1>
                          <button className="text-[28px] text-[#303030]">
                            <span className="text-xs">&#36;</span>
                            <span className="text-[28px] font-bold mx-1 text-[#303030]">
                              49/
                            </span>
                            agent/month/annualy
                          </button>
                        </div>
                        <div className="lg:w-96 lg:border-l-2  xl:border-l-2 text-center py-5 bg-white  border-b-[#A0A0A0] border-b lg:border-r-1 border-black">
                          <h1 className=" text-[16px] text-[#303030]">
                            Enterprise
                          </h1>
                          <button className="text-[28px] text-[#303030]">
                            <span className="text-xs">&#36;</span>
                            <span className="text-[28px] font-bold mx-1 text-[#303030]">
                              69/
                            </span>
                            agent/month/annualy
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div className=" container m-auto chit_one">
                  <div className="flex  justify-between py-4 px-7 bg-[#EDEDED] border-[1px]  border-[#A0A0A0] business-accordion-section">
                    <p className="text-[18px] text-[#464646]">
                      Communication channels
                    </p>
                    <p className="w-[16px] h-[20px]">
                      <img
                        src={sub}
                        onClick={() =>
                          setCommunication((Communication) => !Communication)
                        }
                      />
                    </p>
                  </div>
                  {Communication == true ? (
                    <div className="px-2 bg-[#FFF7F4]">
                      <table className=" px-2 w-full">
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Contact forms – Contact us buttons
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Contact forms – In page
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Slack notifications
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Customer form
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Email ticketing – Incoming email accounts
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Email ticketing – Outgoing email accounts
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Live chat button
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Proactive chat invitations
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Call center
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Feedback buttons
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Viber
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Twitter
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Facebook & Instagram
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            WhatsApp
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Video call functionality
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </section>
              <section>
                <div className="container m-auto chit_one">
                  <div className="flex  justify-between py-4 px-7 bg-[#EDEDED] border-[1px]  border-[#A0A0A0] business-accordion-section">
                    <p className="text-[18px] text-[#464646]">
                      {" "}
                      Conversation management
                    </p>
                    <p className="w-[16px] h-[20px]">
                      <img
                        src={sub}
                        onClick={() =>
                          setConversation((Conversation) => !Conversation)
                        }
                      />
                    </p>
                  </div>
                  {Conversation == true ? (
                    <div className=" px-2 bg-[#FFF7F4]">
                      <table className=" w-full ">
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Ticket history
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Merge tickets
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Custom ticket fields
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Split tickets
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Internal tickets
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Attachments
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Ticket mass actions
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            To Solve Algorithm
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Ticket satisfaction survey
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Custom tags
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Custom ticket filters
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Agent notes to tickets
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Real-time dashboards
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Ticket transfers
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            WYSIWYG editor
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Multiple ticket tabs
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Business hours
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Departments
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Roles in departments
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Canned messages
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Contacts & Groups
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Predefined answers
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Chat transcripts
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            File sharing over chat
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Mobile chat window
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Chat welcome message setting
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Real-time visitors monitor
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Chat Satisfaction Surveys
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Time Tracking
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Call Queueing
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            IVR
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            In call recordings
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Call routing & transfers
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Custom roles
                          </td>

                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                          <td className="enter-prices-section text_center_s imgss">
                            <div>
                              <img src={check} />
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </section>
              <section>
                <div className="container m-auto chit_one">
                  <div className="flex  justify-between py-4 px-7 bg-[#EDEDED] border-[1px]  border-[#A0A0A0] business-accordion-section">
                    <p className="text-[18px] text-[#464646]">Customization</p>
                    <p className="w-[16px] h-[20px]">
                      <img
                        src={sub}
                        onClick={() =>
                          setCustomization((Customization) => !Customization)
                        }
                      />
                    </p>
                  </div>
                  {Customization == true ? (
                    <div className="px-2 bg-[#FFF7F4]">
                      <div className="">
                        <table className="px-2 w-full ">
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket history
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Merge tickets
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom ticket fields
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Split tickets
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Internal tickets
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Attachments
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket mass actions
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              To Solve Algorithm
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket satisfaction survey
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom tags
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom ticket filters
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Agent notes to tickets
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Real-time dashboards
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket transfers
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              WYSIWYG editor
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Multiple ticket tabs
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Business hours
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Departments
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Roles in departments
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Canned messages
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Contacts & Groups
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Predefined answers
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Chat transcripts
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              File sharing over chat
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Mobile chat window
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Chat welcome message setting
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Real-time visitors monitor
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Chat Satisfaction Surveys
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Time Tracking
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Call Queueing
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              IVR
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              In call recordings
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Call routing & transfers
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom roles
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </section>
              <section>
                <div className="container m-auto chit_one">
                  <div className="flex  justify-between py-4 px-7 bg-[#EDEDED] border-[1px]  border-[#A0A0A0] business-accordion-section">
                    <p>Reporting</p>
                    <p className="w-[16px] h-[20px]">
                      <img
                        src={sub}
                        onClick={() => setReporting((Reporting) => !Reporting)}
                      />
                    </p>
                  </div>
                  {Reporting == true ? (
                    <div className="px-2 bg-[#FFF7F4]">
                      <div className="">
                        <table className="px-2 w-full ">
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket history
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Merge tickets
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom ticket fields
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Split tickets
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Internal tickets
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Attachments
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket mass actions
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              To Solve Algorithm
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket satisfaction survey
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom tags
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom ticket filters
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Agent notes to tickets
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Real-time dashboards
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket transfers
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              WYSIWYG editor
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Multiple ticket tabs
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Business hours
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Departments
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Roles in departments
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Canned messages
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Contacts & Groups
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Predefined answers
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Chat transcripts
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              File sharing over chat
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Mobile chat window
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Chat welcome message setting
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Real-time visitors monitor
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Chat Satisfaction Surveys
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Time Tracking
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Call Queueing
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              IVR
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              In call recordings
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Call routing & transfers
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom roles
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </section>
              <section>
                <div className="container m-auto chit_one">
                  <div className="flex  justify-between py-4 px-7 bg-[#EDEDED] border-[1px]  border-[#A0A0A0] business-accordion-section">
                    <p className="text-[18px] text-[#464646]">Integrations</p>
                    <p className="w-[16px] h-[20px]">
                      <img
                        src={sub}
                        onClick={() =>
                          setIntegrations((Integrations) => !Integrations)
                        }
                      />
                    </p>
                  </div>
                  {Integrations == true ? (
                    <div className="px-2">
                      <div className="">
                        <table className="px-2 w-full ">
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket history
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Merge tickets
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom ticket fields
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Split tickets
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Internal tickets
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Attachments
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket mass actions
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              To Solve Algorithm
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket satisfaction survey
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom tags
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom ticket filters
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Agent notes to tickets
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Real-time dashboards
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket transfers
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              WYSIWYG editor
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Multiple ticket tabs
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Business hours
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Departments
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Roles in departments
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Canned messages
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Contacts & Groups
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Predefined answers
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Chat transcripts
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              File sharing over chat
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Mobile chat window
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Chat welcome message setting
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Real-time visitors monitor
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Chat Satisfaction Surveys
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Time Tracking
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Call Queueing
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              IVR
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              In call recordings
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Call routing & transfers
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom roles
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </section>
              <section>
                <div className="container m-auto chit_one">
                  <div className="flex  justify-between py-4 px-7 bg-[#EDEDED] border-[1px]  border-[#A0A0A0] business-accordion-section">
                    <p className="text-[18px] text-[#464646]">
                      Support and services
                    </p>
                    <p className="w-[16px] h-[20px]">
                      <img
                        src={sub}
                        onClick={() => setservices((services) => !services)}
                      />
                    </p>
                  </div>
                  {services == true ? (
                    <div className="px-2">
                      <div className="">
                        <table className="px-2 w-full ">
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket history
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Merge tickets
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom ticket fields
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Split tickets
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Internal tickets
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Attachments
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket mass actions
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              To Solve Algorithm
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket satisfaction survey
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom tags
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom ticket filters
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Agent notes to tickets
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Real-time dashboards
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket transfers
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              WYSIWYG editor
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Multiple ticket tabs
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Business hours
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Departments
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Roles in departments
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Canned messages
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Contacts & Groups
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Predefined answers
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Chat transcripts
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              File sharing over chat
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Mobile chat window
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Chat welcome message setting
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Real-time visitors monitor
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Chat Satisfaction Surveys
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Time Tracking
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Call Queueing
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              IVR
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              In call recordings
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Call routing & transfers
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom roles
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </section>
              <section>
                <div className="container m-auto chit_one">
                  <div className="flex  justify-between py-4 px-7 bg-[#EDEDED] border-[1px]  border-[#A0A0A0] business-accordion-section">
                    <p className="text-[18px] text-[#464646]">
                      Security and permissions
                    </p>
                    <p className="w-[16px] h-[20px]">
                      <img
                        src={sub}
                        onClick={() =>
                          setpermissions((permissions) => !permissions)
                        }
                      />
                    </p>
                  </div>
                  {permissions == true ? (
                    <div className="px-2">
                      <div className="">
                        <table className="px-2 w-full ">
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket history
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Merge tickets
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom ticket fields
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Split tickets
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Internal tickets
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Attachments
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket mass actions
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              To Solve Algorithm
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket satisfaction survey
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom tags
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom ticket filters
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Agent notes to tickets
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Real-time dashboards
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Ticket transfers
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              WYSIWYG editor
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Multiple ticket tabs
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Business hours
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Departments
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Roles in departments
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Canned messages
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Contacts & Groups
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Predefined answers
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Chat transcripts
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              File sharing over chat
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>

                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Mobile chat window
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Chat welcome message setting
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Real-time visitors monitor
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Chat Satisfaction Surveys
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Time Tracking
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Call Queueing
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              IVR
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              In call recordings
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Call routing & transfers
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                          <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                            <td className="business-accordion-heading-section	">
                              Custom roles
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                            <td className="enter-prices-section text_center_s imgss">
                              <div>
                                <img src={check} />
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </section>
              <section>
                <div className="container m-auto chit_one">
                  <div className="flex  justify-between py-4 px-7 bg-[#EDEDED] border-[1px]  border-[#A0A0A0] business-accordion-section">
                    <p className="text-[18px] text-[#464646]">Automation</p>
                    <p className="w-[16px] h-[20px]">
                      <img
                        src={sub}
                        onClick={() =>
                          setAutomation((Automation) => !Automation)
                        }
                      />
                    </p>
                  </div>
                  {Automation == true ? (
                    <div className="px-2">
                      <table className="px-2 w-full ">
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Customer Portal
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            1
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 2
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Contact forms – Contact us buttons
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 1
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 2
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Contact forms – In page
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 1
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 2
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Slack notifications
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 1
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 2
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Customer form
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 1
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 2
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Email ticketing – Incoming email accounts
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 1
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 2
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Email ticketing – Outgoing email accounts
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 1
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 2
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Live chat button
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 1
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 2
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Proactive chat invitations
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 1
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 2
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Call center
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 1
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 2
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Feedback buttons
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 1
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 2
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Viber
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 1
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 2
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Twitter
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 1
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 2
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Facebook & Instagram
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 1
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 2
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            WhatsApp
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 1
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 2
                          </td>
                        </tr>
                        <tr className="m-auto  border-[1px]  border-t-0 border-[#A0A0A0]">
                          <td className="business-accordion-heading-section	">
                            Video call functionality
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 1
                          </td>
                          <td className="w-[500px] border-r-[1px] text-center border-[#A0A0A0] imgss ">
                            section 2
                          </td>
                        </tr>
                      </table>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </section>
            </>
          ) : (
            ""
          )}
        </div>
        <div>
          <Emailpage />
        </div>
      </div>
    </>
  );
}
export default Pricing;
