import React from "react";
const AntiSlavery = () => {
  return (
    <>
      <div className="flex justify-center items-center min-h-screen py-10 bg-gray-100">
        <div className="bg-white shadow-lg rounded-md p-8 max-w-5xl mx-4">
          <h1 className="text-3xl font-bold mb-4" style={{ color: "#000" }}>
            Anti-Slavery Policy
          </h1>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Introduction
            </h3>
            <p className="text-gray-700">
              ChatPro is committed to preventing modern slavery and human
              trafficking in all its forms. This Anti-Slavery Policy outlines
              our approach to ensuring that slavery and human trafficking do not
              occur within our business or supply chains.
            </p>
          </div>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Our Commitment
            </h3>
            <p className="text-gray-700">
              We are dedicated to acting ethically and with integrity in all our
              business dealings. We have a zero-tolerance approach to modern
              slavery and expect our employees, suppliers, and partners to share
              this commitment.
            </p>
          </div>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Our Policies
            </h3>
            <p className="text-gray-700">
              We have implemented policies and procedures to identify and
              mitigate the risk of modern slavery within our operations and
              supply chains. This includes conducting due diligence on our
              suppliers and partners.
            </p>
          </div>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Training and Awareness
            </h3>
            <p className="text-gray-700">
              We provide training to our employees to ensure they understand the
              risks of modern slavery and how to identify and report potential
              issues. We also raise awareness among our suppliers and partners
              about our Anti-Slavery Policy.
            </p>
          </div>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Reporting Concerns
            </h3>
            <p className="text-gray-700">
              We encourage employees, suppliers, and partners to report any
              concerns about modern slavery or human trafficking. Reports can be
              made confidentially to [reporting contact].
            </p>
          </div>

          <div className="mb-6">
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Monitoring and Review
            </h3>
            <p className="text-gray-700">
              We regularly monitor and review our practices to ensure compliance
              with this Anti-Slavery Policy and relevant laws. We are committed
              to continuous improvement in our efforts to combat modern slavery.
            </p>
          </div>

          <div>
            <h3
              className="text-2xl font-semibold mb-2"
              style={{ color: "#000" }}
            >
              Contact Us
            </h3>
            <p className="text-gray-700">
              If you have any questions about this Anti-Slavery Policy, please
              contact us at{" "}
              <a href="mailto:info@ChatPro.com">info@ChatPro.com</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AntiSlavery;
