import React, { useEffect, useState, useRef } from "react";
import { usePopup } from "../Common/PopupContext";
import Login from "../Website/Login";

import Cookies from "js-cookie";
import { getUserData } from "../../helpers/utils";
import usericon from "../../image/ri_arrow-drop.png";
import Logouticon from "../../image/material-symbols_logout.png";
import logo from "../../image/chatlogo.webp";
import FeatherIcon from "feather-icons-react";
import { Link, useLocation, useHistory } from "react-router-dom";
import CustomToastContainer from "../Common/CustomToastContainer";
const clientDomain = process.env.REACT_APP_DOMAIN;
// import FeatherIcon from "feather-icons-react"; // Uncomment if needed
const User = getUserData();

const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;
const clientdomainpath = process.env.REACT_APP_DOMAIN;

const Header = () => {
  const [activeLink, setActiveLink] = useState("");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const toastRef = useRef();
  useEffect(() => {
    // console.log(User);
    const pathname = location.pathname;
    const normalizedPathname = pathname.endsWith("/")
      ? pathname.slice(0, -1)
      : pathname;
    setActiveLink(normalizedPathname);
  }, [location.pathname]);

  const handleLinkClick = (linkName) => {
    const normalizedLinkName = linkName.endsWith("/")
      ? linkName.slice(0, -1)
      : linkName;
    setActiveLink(normalizedLinkName);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const { openPopup } = usePopup();
  const [popupComponent, setPopupComponent] = useState(null);

  const handleClosePopup = (message) => {
    console.log(message);
    setPopupComponent(null);
    openPopup();
  };

  const handleLoginClick = () => {
    const popup = <Login onClose={() => handleClosePopup("Popup closed")} />;
    setPopupComponent(popup);
    openPopup(popup);
  };

  const NavLinks = () => (
    <div className="chp-mobile-navbaar bg-white">
      <div className="navbaarouter-section">
        <div className="lg:flex items-center">
          <nav>
            <ul className="lg:gap-5 md:gap-2">
              <Link to="/" onClick={() => handleLinkClick("/")}>
                <li
                  className={`nav-link ${activeLink === "/" ? "active" : ""}`}
                >
                  HOME
                </li>
              </Link>
              <Link to="/feature" onClick={() => handleLinkClick("/feature")}>
                <li
                  className={`nav-link ${
                    activeLink === "/feature" ? "active" : ""
                  }`}
                >
                  FEATURE
                </li>
              </Link>
              <Link to="/pricing" onClick={() => handleLinkClick("/pricing")}>
                <li
                  className={`nav-link ${
                    activeLink === "/pricing" ? "active" : ""
                  }`}
                >
                  PRICING
                </li>
              </Link>
              <Link to="/blog" onClick={() => handleLinkClick("/blog")}>
                <li
                  className={`nav-link ${
                    activeLink === "/blog" ? "active" : ""
                  }`}
                >
                  BLOGS
                </li>
              </Link>
              <Link to="/demo" onClick={() => handleLinkClick("/demo")}>
                <li
                  className={`nav-link ${
                    activeLink === "/demo" ? "active" : ""
                  }`}
                >
                  DEMOS
                </li>
              </Link>
              <Link to="/trail" onClick={() => handleLinkClick("/trail")}>
                <li
                  className={`nav-link ${
                    activeLink === "/trail" ? "active" : ""
                  }`}
                >
                  TRIAL
                </li>
              </Link>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
  const [isVisible, setIsVisible] = useState(false);
  const history = useHistory();

  const toggleSection = () => {
    setIsVisible(!isVisible);
  };
  const handleLogout = () => {
    // Check if admin cookies are present
    const adminAccessToken = Cookies.get("adminaccessToken");
    const adminRefreshToken = Cookies.get("adminrefreshToken");
    const adminUser = Cookies.get("adminuser");

    if (adminAccessToken && adminRefreshToken && adminUser) {
      // If admin cookies are present, remove standard cookies
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      Cookies.remove("user");

      // Rename admin cookies to standard cookie names
      Cookies.set("accessToken", adminAccessToken);
      Cookies.set("refreshToken", adminRefreshToken);
      Cookies.set("user", adminUser);

      // Remove admin cookies
      Cookies.remove("adminaccessToken");
      Cookies.remove("adminrefreshToken");
      Cookies.remove("adminuser");
      toastRef.current.addToast("Logout successfully", "success");
      // Clear local storage
      localStorage.clear();
      sessionStorage.clear();
      // Redirect to home page
      window.location.href = "/";
    } else {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      Cookies.remove("user");
      toastRef.current.addToast("Logout successfully", "success");
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/";
    }
  };

  return (
    <header className="">
      <div className="container-header container mx-auto">
        <div className=" flex justify-between items-center sticky  z-20 nav_bar  ">
          <div className="analybiz-logo">
            <a href="/">
              <img src={logo} width={""} alt="analybiz Logo" />
            </a>
          </div>
          <div className="navbaarouter-section-outer">
            <div className="navbaarouter-section">
              <div className="flex items-center">
                <nav>
                  <ul className="flex lg:gap-5 md:gap-2">
                    <Link to="/" onClick={() => handleLinkClick("/")}>
                      <li
                        className={`nav-link ${
                          activeLink === "/" ? "active" : ""
                        }`}
                      >
                        HOME
                      </li>
                    </Link>
                    <Link
                      to="/feature"
                      onClick={() => handleLinkClick("/feature")}
                    >
                      <li
                        className={`nav-link ${
                          activeLink === "/feature" ? "active" : ""
                        }`}
                      >
                        FEATURE
                      </li>
                    </Link>
                    <Link
                      to="/pricing"
                      onClick={() => handleLinkClick("/pricing")}
                    >
                      <li
                        className={`nav-link ${
                          activeLink === "/pricing" ? "active" : ""
                        }`}
                      >
                        PRICING
                      </li>
                    </Link>
                    <Link to="/blog" onClick={() => handleLinkClick("/blog")}>
                      <li
                        className={`nav-link ${
                          activeLink === "/blog" ? "active" : ""
                        }`}
                      >
                        BOLGS
                      </li>
                    </Link>
                    <Link to="/demo" onClick={() => handleLinkClick("/demo")}>
                      <li
                        className={`nav-link ${
                          activeLink === "/demo" ? "active" : ""
                        }`}
                      >
                        DEMOS
                      </li>
                    </Link>
                    <Link to="/trail" onClick={() => handleLinkClick("/trail")}>
                      <li
                        className={`nav-link ${
                          activeLink === "/trail" ? "active" : ""
                        }`}
                      >
                        TRIAL
                      </li>
                    </Link>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          <div className="flex gap-3 items-center">
            {/* {User ? (
              <div className="seller-btn-width">
                <button className="Job-Post-btn">
                  <a href="/dashboard/add-course">Add Course</a>
                </button>
              </div>
            ) : (
              ""
            )} */}
            {User ? (
              <div className="flex items-center gap-3">
                <div>
                  <img
                    className="w-16 profileimgheader"
                    src={
                      User?.profileimg != undefined
                        ? domainpath + User?.profileimg
                        : domainpath + "/uploads/deafultman.png"
                    }
                  />
                </div>
                <div className="flex items-center userprofile-text">
                  <div>
                    <div
                      className="flex cursor-pointer"
                      onClick={toggleSection}
                    >
                      <div>
                        <img className="" src={usericon}></img>
                      </div>
                    </div>
                    {isVisible && (
                      <div className="dropdown-content cursor-pointer absolute right-6 mt-2 w-40 bg-white rounded-md shadow-lg z-10">
                        <div className="trangle-upper">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path d="M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8l256 0c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
                          </svg>
                        </div>
                        <div>
                          <a
                            href="/dashboard"
                            className="block px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center space-x-2"
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.70483 8.99998H3.1215C2.77922 9.00192 2.45163 9.13924 2.21029 9.38196C1.96896 9.62467 1.83349 9.95304 1.8335 10.2953V12.8786C1.8335 13.2202 1.9692 13.5479 2.21074 13.7894C2.45229 14.0309 2.7799 14.1666 3.1215 14.1666H5.70483C6.04704 14.1667 6.37534 14.0312 6.61795 13.7898C6.86055 13.5484 6.99773 13.2209 6.9995 12.8786V10.2953C6.9995 9.95195 6.86309 9.62264 6.6203 9.37984C6.3775 9.13705 6.0482 9.00065 5.70483 9.00065M5.70483 1.83331H3.1215C2.95235 1.83331 2.78487 1.86663 2.6286 1.93136C2.47233 1.99608 2.33034 2.09096 2.21074 2.21056C2.09114 2.33016 1.99627 2.47215 1.93154 2.62842C1.86681 2.78468 1.8335 2.95217 1.8335 3.12131V5.70465C1.83349 6.04686 1.96898 6.37516 2.21034 6.61776C2.45169 6.86037 2.77929 6.99755 3.1215 6.99931H5.70483C6.0482 6.99931 6.3775 6.86291 6.6203 6.62011C6.86309 6.37732 6.9995 6.04801 6.9995 5.70465V3.12131C6.99773 2.7791 6.86055 2.45151 6.61795 2.21015C6.37534 1.96879 6.04704 1.83331 5.70483 1.83331ZM12.8788 1.83331H10.2955C9.95328 1.83331 9.62498 1.96879 9.38238 2.21015C9.13977 2.45151 9.00259 2.7791 9.00083 3.12131V5.70465C9.00101 6.04796 9.13746 6.37716 9.38022 6.61992C9.62298 6.86268 9.95218 6.99914 10.2955 6.99931H12.8788C13.221 6.99755 13.5486 6.86037 13.79 6.61776C14.0313 6.37516 14.1668 6.04686 14.1668 5.70465V3.12131C14.1668 2.77971 14.0311 2.45211 13.7896 2.21056C13.548 1.96901 13.2204 1.83331 12.8788 1.83331Z"
                                stroke="#000"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M11.5788 9.2113V13.9553M9.21143 11.5886H13.9554"
                                stroke="#000"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                              />
                            </svg>
                            <span>Dashboard</span>
                          </a>

                          <div
                            className="block px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center space-x-2"
                            onClick={handleLogout}
                          >
                            <FeatherIcon icon="log-out" />
                            <span>Logout</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div onClick={handleLogout} className="cursor-pointer">
                  <img src={Logouticon} />
                </div>
              </div>
            ) : (
              <div
                onClick={handleLoginClick}
                className="cursor-pointer analybiz-login-btn hover:transition "
              >
                <h1 className="">Log In </h1>
                <span className="flex">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3 160 480c0 17.7 14.3 32 32 32s32-14.3 32-32l0-370.7 73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                  </svg>
                </span>
              </div>
            )}
            {!User ? "" : ""}
            <div className="chp-toggleMobileMenu">
              <button onClick={toggleMobileMenu} className="text-white">
                <svg
                  width="30px"
                  height="30px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M0 96C0 78.3 14.3 64 32 64h384c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zm0 160c0-17.7 14.3-32 32-32h384c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zm448 160c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h384c17.7 0 32 14.3 32 32z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`fixed inset-y-0 right-0 left-0  top-12 z-50  text-black transform chp-mobile-outer ${
          isMobileMenuOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out md:hidden`}
      >
        <NavLinks />
      </div>
      <CustomToastContainer ref={toastRef} />
    </header>
  );
};
const EmployerHeader = () => {
  const [activeLink, setActiveLink] = useState("/uploadcv");
  const [isVisible, setIsVisible] = useState(false);
  const history = useHistory();
  const toastRef = useRef();
  const dropdownRef = useRef(null);
  const toggleSection = () => {
    setIsVisible(!isVisible);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleLinkClick = (linkName) => {
    const url = window.location.href;
    const pathname = new URL(url).pathname; // Normalize both linkName and pathname to ensure a correct comparison
    const normalizedLinkName = linkName.endsWith("/")
      ? linkName.slice(0, -1)
      : linkName;
    const normalizedPathname = pathname.endsWith("/")
      ? pathname.slice(0, -1)
      : pathname;
    if (normalizedLinkName === normalizedPathname) {
      setActiveLink(normalizedLinkName);
    }
  };
  const handleLogout = () => {
    const adminAccessToken = Cookies.get("adminaccessToken");
    const adminRefreshToken = Cookies.get("adminrefreshToken");
    const adminUser = Cookies.get("adminuser");

    if (adminAccessToken && adminRefreshToken && adminUser) {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      Cookies.remove("user");
      Cookies.set("accessToken", adminAccessToken);
      Cookies.set("refreshToken", adminRefreshToken);
      Cookies.set("user", adminUser);
      Cookies.remove("adminaccessToken");
      Cookies.remove("adminrefreshToken");
      Cookies.remove("adminuser");
      localStorage.clear();
      sessionStorage.clear();
      toastRef.current.addToast("Logged out successfully", "success");

      // Delay page reload to let the toast show
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    } else {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      Cookies.remove("user");
      localStorage.clear();
      toastRef.current.addToast("Logged out successfully", "success");

      // Delay page reload to let the toast show
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    }
  };

  /* ======= For Popup ====== */
  const { openPopup } = usePopup();
  const handleLoginClick = () => {
    openPopup(<Login />);
  };
  /* ======= Ends Popup ====== */

  return (
    <>
      <header className="container-main text-white py-2 user-navbar ">
        <div className="">
          <div className="container mx-auto flex justify-between items-center hrp-applicant-header-inner">
            <div className="HirePros-logo">
              <a href="/">
                <img src={logo} width={""} alt="analybiz Logo" />
              </a>
            </div>
            <div className="hrp-EmployerHeader-section-main-section">
              <div className="">
                <div className="">
                  <div className="navbaarouter-section">
                    <div className="flex items-center">
                      <nav>
                        <ul className="flex"></ul>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="flex gap-3 items-center login-section hrp-mobile-responcive-header">
                  <button
                    className="Job-Post-btn btnpostjob"
                    onClick={() => history.push(`/post-job`)}
                  >
                    Post Job
                  </button>
                </div>
              </div>
              <div>
                <div className="flex items-center gap-3">
                  <div>
                    <img
                      className="w-16 h-16 profileimgheader"
                      width="64"
                      height="64"
                      src={
                        User?.profileimg
                          ? User?.profileimg.startsWith("http")
                            ? User?.profileimg
                            : `${domainpath}/uploads/${User?.profileimg}`
                          : domainpath + "/uploads/deafultman.png" // Use a default image if company or logo is missing
                      }
                      onError={(e) => {
                        e.target.onerror = null; // Prevents infinite loop if default image also fails
                        e.target.src = domainpath + "/uploads/deafultman.png"; // Set the path to your default image here
                      }}
                      alt="profileimg"
                    />
                  </div>
                  <div className="flex items-center userprofile-text">
                    <div>
                      <div
                        className="flex cursor-pointer"
                        onClick={toggleSection}
                      >
                        <h1 className="capitalize">Welcome {User?.name}</h1>
                        <div>
                          <img className="" src={usericon} alt="usericon"></img>
                        </div>
                      </div>
                      {isVisible && (
                        <div
                          className="dropdown-content cursor-pointer absolute right-6 mt-2 w-40 bg-white rounded-md shadow-lg z-10"
                          ref={dropdownRef}
                        >
                          <div className="trangle-upper">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                            >
                              <path d="M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8l256 0c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
                            </svg>
                          </div>
                          <div>
                            <a
                              href="/dashboard"
                              target=""
                              className="block px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center space-x-2"
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.70483 8.99998H3.1215C2.77922 9.00192 2.45163 9.13924 2.21029 9.38196C1.96896 9.62467 1.83349 9.95304 1.8335 10.2953V12.8786C1.8335 13.2202 1.9692 13.5479 2.21074 13.7894C2.45229 14.0309 2.7799 14.1666 3.1215 14.1666H5.70483C6.04704 14.1667 6.37534 14.0312 6.61795 13.7898C6.86055 13.5484 6.99773 13.2209 6.9995 12.8786V10.2953C6.9995 9.95195 6.86309 9.62264 6.6203 9.37984C6.3775 9.13705 6.0482 9.00065 5.70483 9.00065M5.70483 1.83331H3.1215C2.95235 1.83331 2.78487 1.86663 2.6286 1.93136C2.47233 1.99608 2.33034 2.09096 2.21074 2.21056C2.09114 2.33016 1.99627 2.47215 1.93154 2.62842C1.86681 2.78468 1.8335 2.95217 1.8335 3.12131V5.70465C1.83349 6.04686 1.96898 6.37516 2.21034 6.61776C2.45169 6.86037 2.77929 6.99755 3.1215 6.99931H5.70483C6.0482 6.99931 6.3775 6.86291 6.6203 6.62011C6.86309 6.37732 6.9995 6.04801 6.9995 5.70465V3.12131C6.99773 2.7791 6.86055 2.45151 6.61795 2.21015C6.37534 1.96879 6.04704 1.83331 5.70483 1.83331ZM12.8788 1.83331H10.2955C9.95328 1.83331 9.62498 1.96879 9.38238 2.21015C9.13977 2.45151 9.00259 2.7791 9.00083 3.12131V5.70465C9.00101 6.04796 9.13746 6.37716 9.38022 6.61992C9.62298 6.86268 9.95218 6.99914 10.2955 6.99931H12.8788C13.221 6.99755 13.5486 6.86037 13.79 6.61776C14.0313 6.37516 14.1668 6.04686 14.1668 5.70465V3.12131C14.1668 2.77971 14.0311 2.45211 13.7896 2.21056C13.548 1.96901 13.2204 1.83331 12.8788 1.83331Z"
                                  stroke="#003366"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M11.5788 9.2113V13.9553M9.21143 11.5886H13.9554"
                                  stroke="#003366"
                                  strokeWidth="1.5"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                />
                              </svg>
                              <span>Dashboard</span>
                            </a>
                            <a
                              target=""
                              href="/dashboard/site-settings"
                              className="block px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center space-x-2"
                            >
                              <svg
                                className="icon-profile w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5.121 19.071A8.96 8.96 0 0112 20a8.96 8.96 0 016.879-2.929M12 14a7 7 0 110-14 7 7 0 010 14zm-7.071 5.071a9.971 9.971 0 0114.142 0"
                                />
                              </svg>
                              <span>My profile</span>
                            </a>
                            <div
                              className="block px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center space-x-2"
                              onClick={handleLogout}
                            >
                              <FeatherIcon icon="log-out" />
                              <span>Logout</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div onClick={handleLogout} className="cursor-pointer">
                    <img src={Logouticon} alt="Logouticon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>{" "}
      <CustomToastContainer ref={toastRef} />
    </>
  );
};
const CandidateHeader = () => {
  const [activeLink, setActiveLink] = useState("/uploadcv");
  const [isVisible, setIsVisible] = useState(false);
  const history = useHistory();
  const toastRef = useRef();

  const toggleSection = () => {
    setIsVisible(!isVisible);
  };
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsVisible(false); // Close the dropdown
    }
  };
  useEffect(() => {
    // Add event listener to detect clicks outside the dropdown
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove event listener when component is unmounted
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleLinkClick = (linkName) => {
    const url = window.location.href;
    const pathname = new URL(url).pathname;

    // Normalize both linkName and pathname to ensure a correct comparison
    const normalizedLinkName = linkName.endsWith("/")
      ? linkName.slice(0, -1)
      : linkName;
    const normalizedPathname = pathname.endsWith("/")
      ? pathname.slice(0, -1)
      : pathname;
    if (normalizedLinkName === normalizedPathname) {
      setActiveLink(normalizedLinkName);
    }
  };

  const handleLogout = () => {
    // Check if admin cookies are present
    const adminAccessToken = Cookies.get("adminaccessToken");
    const adminRefreshToken = Cookies.get("adminrefreshToken");
    const adminUser = Cookies.get("adminuser");

    if (adminAccessToken && adminRefreshToken && adminUser) {
      // If admin cookies are present, remove standard cookies
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      Cookies.remove("user");

      // Rename admin cookies to standard cookie names
      Cookies.set("accessToken", adminAccessToken);
      Cookies.set("refreshToken", adminRefreshToken);
      Cookies.set("user", adminUser);

      // Remove admin cookies
      Cookies.remove("adminaccessToken");
      Cookies.remove("adminrefreshToken");
      Cookies.remove("adminuser");
      // Clear local storage
      localStorage.clear();
      sessionStorage.clear();
      // Redirect to home page
      toastRef.current.addToast("Logged out successfully", "success");

      // Delay page reload to let the toast show
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    } else {
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      Cookies.remove("user");
      localStorage.clear();
      toastRef.current.addToast("Logged out successfully", "success");

      // Delay page reload to let the toast show
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
      sessionStorage.clear();
    }
  };
  /* ======= For Popup ====== */
  const { openPopup } = usePopup();
  const handleLoginClick = () => {
    openPopup(<Login />);
  };
  /* ======= Ends Popup ====== */
  return (
    <>
      <header className="container-main  text-white py-2 user-navbar hrp-applicant-header ">
        <div className="">
          <div className="container mx-auto flex lg:justify-between justify-center items-center hrp-applicant-header-inner">
            <div className="HirePros-logo">
              <a href="/" target="">
                <picture>
                  <source
                    srcSet={logo}
                    type="image/webp"
                    media="(max-width: 600px)"
                  />
                  <source
                    srcSet={logo}
                    type="image/webp"
                    media="(max-width: 1200px)"
                  />
                  <img
                    src={logo}
                    width="100%"
                    height="200"
                    //// sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 25vw"
                    alt="logo "
                  />
                </picture>
              </a>
            </div>
            <div className="hrp-EmployerHeader-section-main-section">
              <div className="">
                <div className="navbaarouter-section">
                  <div className="flex items-center">
                    <nav>
                      <ul className="flex">
                        <Link
                          to={"/jobs?applicantId=" + User?._id}
                          onClick={() => handleLinkClick("/jobs")}
                        >
                          <li
                            className={`nav-link ${
                              activeLink === "/jobs"
                                ? "active rounded-full"
                                : ""
                            }`}
                          >
                            Find Job
                          </li>
                        </Link>
                        {/* <li
                          className={`nav-link ${
                            activeLink == "/jobs" ? "active" : ""
                          }`}
                        >
                          <Link
                            to="/jobs"
                            onClick={(e) => handleLinkClick("/jobs", e)}
                          >
                            Find Job
                          </Link>
                        </li> */}
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex items-center gap-3">
                  <div>
                    <img
                      className="w-16 h-16 profileimgheader"
                      width="64"
                      height="64"
                      src={
                        User?.profileimg
                          ? User?.profileimg.startsWith("http")
                            ? User?.profileimg
                            : `${domainpath}/uploads/${User?.profileimg}`
                          : domainpath + "/uploads/deafultman.png" // Use a default image if company or logo is missing
                      }
                      onError={(e) => {
                        e.target.onerror = null; // Prevents infinite loop if default image also fails
                        e.target.src = domainpath + "/uploads/deafultman.png"; // Set the path to your default image here
                      }}
                      alt="profileimg"
                    />
                  </div>
                  <div className="flex items-center userprofile-text">
                    <div>
                      <div
                        className="flex cursor-pointer"
                        onClick={toggleSection}
                      >
                        <h1 className="capitalize">Welcome {User?.name}</h1>
                        <div>
                          <img className="" src={usericon} alt="usericon"></img>
                        </div>
                      </div>
                      {isVisible && (
                        <div
                          className="dropdown-content cursor-pointer absolute right-6 mt-2 w-40 bg-white rounded-md shadow-lg z-10"
                          ref={dropdownRef}
                        >
                          <div className="trangle-upper">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                            >
                              <path d="M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8l256 0c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
                            </svg>
                          </div>
                          <div>
                            <a
                              href="/dashboard"
                              target=""
                              className="block px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center space-x-2"
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.70483 8.99998H3.1215C2.77922 9.00192 2.45163 9.13924 2.21029 9.38196C1.96896 9.62467 1.83349 9.95304 1.8335 10.2953V12.8786C1.8335 13.2202 1.9692 13.5479 2.21074 13.7894C2.45229 14.0309 2.7799 14.1666 3.1215 14.1666H5.70483C6.04704 14.1667 6.37534 14.0312 6.61795 13.7898C6.86055 13.5484 6.99773 13.2209 6.9995 12.8786V10.2953C6.9995 9.95195 6.86309 9.62264 6.6203 9.37984C6.3775 9.13705 6.0482 9.00065 5.70483 9.00065M5.70483 1.83331H3.1215C2.95235 1.83331 2.78487 1.86663 2.6286 1.93136C2.47233 1.99608 2.33034 2.09096 2.21074 2.21056C2.09114 2.33016 1.99627 2.47215 1.93154 2.62842C1.86681 2.78468 1.8335 2.95217 1.8335 3.12131V5.70465C1.83349 6.04686 1.96898 6.37516 2.21034 6.61776C2.45169 6.86037 2.77929 6.99755 3.1215 6.99931H5.70483C6.0482 6.99931 6.3775 6.86291 6.6203 6.62011C6.86309 6.37732 6.9995 6.04801 6.9995 5.70465V3.12131C6.99773 2.7791 6.86055 2.45151 6.61795 2.21015C6.37534 1.96879 6.04704 1.83331 5.70483 1.83331ZM12.8788 1.83331H10.2955C9.95328 1.83331 9.62498 1.96879 9.38238 2.21015C9.13977 2.45151 9.00259 2.7791 9.00083 3.12131V5.70465C9.00101 6.04796 9.13746 6.37716 9.38022 6.61992C9.62298 6.86268 9.95218 6.99914 10.2955 6.99931H12.8788C13.221 6.99755 13.5486 6.86037 13.79 6.61776C14.0313 6.37516 14.1668 6.04686 14.1668 5.70465V3.12131C14.1668 2.77971 14.0311 2.45211 13.7896 2.21056C13.548 1.96901 13.2204 1.83331 12.8788 1.83331Z"
                                  stroke="#003366"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M11.5788 9.2113V13.9553M9.21143 11.5886H13.9554"
                                  stroke="#003366"
                                  strokeWidth="1.5"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                />
                              </svg>
                              <span>Dashboard</span>
                            </a>
                            <a
                              href={clientdomainpath + "/dashboard/profile"}
                              target=""
                              className="block px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center space-x-2"
                            >
                              <svg
                                className="icon-profile w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5.121 19.071A8.96 8.96 0 0112 20a8.96 8.96 0 016.879-2.929M12 14a7 7 0 110-14 7 7 0 010 14zm-7.071 5.071a9.971 9.971 0 0114.142 0"
                                />
                              </svg>
                              <span>My profile</span>
                            </a>
                            <div
                              className="block px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center space-x-2"
                              onClick={handleLogout}
                            >
                              <FeatherIcon icon="log-out" />
                              <span>Logout</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div onClick={handleLogout} className="cursor-pointer">
                    <img src={Logouticon} alt="Logouticon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomToastContainer ref={toastRef} />
      </header>
    </>
  );
};

export { Header, EmployerHeader, CandidateHeader };
