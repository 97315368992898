// AdminLayout.js
import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "./Footer";
import "../../components/Layout/menubaar.css";
import "../../../src/css/responcive.css";
import { CVDownloadProvider } from "../../helpers/CVDownloadContext";
import { EmployerHeader, Header } from "./Header";
import Signup from "../../components/Website/Signup.js";
import Registepage from "../../components/Website/Registerform.js";
import TermAndConditions from "../../components/Website/TermAndConditions.js";
import PrivacyPolicy from "../../components/Website/PrivacyPolicy.js";
import AntiSlavery from "../../components/Website/AntiSlaveryPolicy.js";
import ForgotPassword from "../../components/Website/ForgotPassword.js";

import { PopupProvider } from "../Common/PopupContext";
import { getUserData } from "../../helpers/utils";
import NotFound from "../Website/NotFound.js";

import HomePage from "../Website/home.js";
import Feature from "../Website/Feature.js";
import Pricing from "../Website/pricing.js";
import blogpage from "../Website/blog.js";
import Demopage from "../Website/demo.js";
import Trailpage from "../Website/Trailpage.js";
import Blogs from "../../components/Website/blogs.js";
import SingleBlogs from "../../components/Website/singleblog.js";
import Faqpage from "../../components/Website/Faqpage.js";
// import Portfoliopage from "../../components/Website/Portfoliopage.js";
function Websitelayout({ userRole }) {
  const useLocalStorage = (key) => {
    const storedValue = localStorage.getItem(key);
    return storedValue;
  };

  const user = useLocalStorage("accessToken");
  useEffect(() => {}, [user]);
  const User = getUserData(); // Get user data from localStorage
  const role = User ? User.role : null; // Get the base path for nested routes
  const renderComponentBasedOnRole = (role) => {
    switch (role) {
      case "superadmin":
        return <Header />;
      case "applicant":
        return <EmployerHeader />;
      case "employer":
        return <Header />;
      default:
        return <Header />; // Default to general header for non-logged-in users or unknown roles
    }
  };
  return (
    <CVDownloadProvider>
      <PopupProvider>
        {renderComponentBasedOnRole(role)}

        <Switch>
          {/* <Route exact path="/" component={Loginform} /> */}
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/" component={HomePage} />
          <Route exact path="/feature" component={Feature} />
          <Route exact path="/pricing" component={Pricing} />
          <Route exact path="/register" component={Registepage} />
          <Route exact path="/terms-conditions" component={TermAndConditions} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/anti-slavery" component={AntiSlavery} />
          <Route exact path="/blogs" component={Blogs} />
          <Route exact path="/blog" component={blogpage} />
          <Route exact path="/demo" component={Demopage} />
          <Route exact path="/trail" component={Trailpage} />
          <Route exact path="/blog/title" component={SingleBlogs} />
          <Route exact path="/faq" component={Faqpage} />
          {/* <Route exact path="/portfolio" component={Portfoliopage} /> */}

          {/* not linked */}
          <Route path="*" component={NotFound} />
        </Switch>

        <Footer />
      </PopupProvider>
    </CVDownloadProvider>
  );
}

export default Websitelayout;
