import React, { useState } from "react";
import { getUserData } from "../../helpers/utils";
const CodeSnippet = () => {
  const [copiedIframe, setCopiedIframe] = useState(false);
  const [copiedScript, setCopiedScript] = useState(false);
  const User = getUserData();
  const analybiz = "//89.117.144.11";
  const userId = User?._id; // Replace with logic to fetch logged-in user ID
  const iframeCode = `
    <div class="chat-launcher"></div>
    <div class="chat-wrapper">
      <iframe src="http:${analybiz}:8003/?id=${userId}" title="Chat Window"></iframe>
    </div>
  `;
  const scriptCode = `
      <link
      rel="stylesheet"
      href="http:${analybiz}assets/css/iframe.css"
    />
    <script>
      document.addEventListener("DOMContentLoaded", function () {
        const chatLauncher = document.querySelector(".chat-launcher");
        const chatWrapper = document.querySelector(".chat-wrapper");
        chatLauncher.addEventListener("click", function () {
          chatWrapper.classList.toggle("is-open");
        });
      });
    </script>
  `;
  const handleCopy = (code, setCopied) => {
    navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied status after 2 seconds
  };

  return (
    <div className="p-4 bg-gray-100 border border-gray-300 rounded-md xl:max-w-4xl lg:max-w-3xl md:max-w-2xl  mx-auto shadow-md space-y-6">
      <div>
        <h2
          className="text-lg font-semibold mt-5 mb-3"
          style={{ color: "#00023f" }}
        >
          Copy your iframe code And Put it into body tag:
        </h2>
        <textarea
          className="w-full p-3 bg-gray-50 border border-gray-300 rounded-md text-sm font-mono resize-none custom-scrollbar"
          value={iframeCode}
          readOnly
          rows={6}
        />
        <button
          onClick={() => handleCopy(iframeCode, setCopiedIframe)}
          className="mt-3 px-4 py-2  text-white font-medium rounded-md  focus:outline-none focus:ring-2  focus:ring-offset-2"
          style={{ backgroundColor: "#ff6800" }}
        >
          {copiedIframe ? "Copied!" : "Copy to Clipboard"}
        </button>
      </div>
      <div>
        <h2
          className="text-lg font-semibold mt-5 mb-3"
          style={{ color: "#00023f" }}
        >
          Copy your script code And Put it into head tag:
        </h2>
        <textarea
          className="w-full p-3 bg-gray-50 border border-gray-300 rounded-md text-sm font-mono resize-none custom-scrollbar"
          value={scriptCode}
          readOnly
          rows={10}
        />
        <button
          onClick={() => handleCopy(scriptCode, setCopiedScript)}
          className="mt-3 px-4 py-2  text-white font-medium rounded-md  focus:outline-none focus:ring-2  focus:ring-offset-2"
          style={{ backgroundColor: "#ff6800" }}
        >
          {copiedScript ? "Copied!" : "Copy to Clipboard"}
        </button>
      </div>
    </div>
  );
};

export default CodeSnippet;
