import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Select2 from "react-select2-wrapper";
import api from "../../Api";
import { useHistory } from "react-router-dom";
import { getUserData } from "../../helpers/utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const TicketForm = () => {
  const User = getUserData();
  const [data, setdata] = useState([]);
  const [userdata, setuserdata] = useState([]);
  const [render, setRender] = useState(0);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [formData, setFormData] = useState({
    userId: "",
    assignedTo: "",
    jobId: "",
    subject: "",
    description: "",
    status: "",

    currentJobStatus: "", // New field for job status
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    // If the textarea id is "Query", map it to the "description" field in formData
    if (id === "description") {
      setFormData({ ...formData, description: value });
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };

  const handleSelectChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const handleMultiSelectChange = (e, field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: Array.from(e.target.selectedOptions, (option) => option.value),
    }));
  };
  useEffect(() => {
    async function getJobs() {
      try {
        const response = await api.getJobsByAdmin();
        if (response.status !== 400) {
          setdata(
            response?.jobsWithApplicants.map((job) => ({
              value: job._id, // Assuming _id is the unique identifier for jobs
              label: job.title, // Job title will be displayed in the dropdown
            }))
          );
        } else if (response.status === 400) {
        }
      } catch (error) {
        console.error(error);
      }
    }
    getJobs();
    async function getusers() {
      try {
        const response = await api.getUsers();
        if (response.status !== 400) {
          setuserdata(
            response.map((user) => ({
              value: user._id, // Assuming _id is the unique identifier for jobs
              label: user.name, // Job title will be displayed in the dropdown
            }))
          );
        } else if (response.status == 400) {
        }
      } catch (error) {
        console.error(error);
        // Handle error
      }
    }
    getusers();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(formData);
    try {
      const response = await api.addTicket(formData);
      if (response.status == 201) {
        toast.success("Ticket Created Successffully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom-toast",
        });
        setLoading(false);

        setTimeout(() => {
          history.push(`./edit-ticket/${response?.ticket?._id}`);
        }, 1000);
      } else {
        console.error("Error posting job:", response.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting course:", error);
      // alert("Failed to submit course. Please try again.");
    } finally {
      // Stop the loader regardless of success or failure
      setLoading(false);
    }
  };

  const dummyJobStatusOptions = [
    { value: "Open", label: "Open" },
    { value: "In Progress", label: "In Progress" },
    { value: "Closed", label: "Closed" },
  ];

  return (
    <div>
      <div className="w-3/4 m-auto p-10">
        <div className="py-5 text-3xl font-bold" style={{ color: "#000" }}>
          Create Ticket
        </div>
        <form
          onSubmit={handleSubmit}
          className="animated-form profileheadlinediv space-y-6"
        >
          <input type="hidden" id="userId" value={formData.userId} />
          <div className="grid grid-cols-2 gap-5">
            <div className="">
              <div className="relative job-search-label-section singleselect2">
                <Select2
                  id="jobId"
                  className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                  name="jobId"
                  multiple={false}
                  defaultValue={formData.jobId}
                  onChange={(e) => handleSelectChange(e, "jobId")}
                  data={data.map((jobdata) => ({
                    id: jobdata.value,
                    text: jobdata.label,
                  }))}
                  options={{
                    placeholder: "Select a job",
                    theme: "classic",
                    width: "resolve",
                  }}
                />
                <label
                  htmlFor="jobId"
                  className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                >
                  Job ID
                </label>
              </div>
            </div>
            <div className="">
              <div className="relative job-search-label-section singleselect2">
                <Select2
                  id="assignedTo"
                  className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                  name="assignedTo"
                  multiple={false}
                  defaultValue={formData.assignedTo}
                  onChange={(e) => handleSelectChange(e, "assignedTo")}
                  data={userdata.map((user) => ({
                    id: user.value,
                    text: user.label,
                  }))}
                  options={{
                    placeholder: "",
                    theme: "classic",
                    width: "resolve",
                  }}
                />
                <label
                  htmlFor="assignedTo"
                  className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                >
                  Assigned To
                </label>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-5">
            <div className="">
              <div className="relative job-search-label-section singleselect2 mt-1">
                <Select2
                  id="statusOptions"
                  className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                  name="statusOptions"
                  multiple={false}
                  defaultValue={formData.status}
                  onChange={(e) => handleSelectChange(e, "status")}
                  data={dummyJobStatusOptions.map((status) => ({
                    id: status.value,
                    text: status.label,
                  }))}
                  options={{
                    placeholder: "",
                    theme: "classic",
                    width: "resolve",
                  }}
                />
                <label
                  htmlFor="statusOptions"
                  className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                >
                  Status
                </label>
              </div>
            </div>{" "}
            <div className="">
              <div className="form-group">
                <input
                  id="subject"
                  placeholder=""
                  value={formData.subject}
                  onChange={handleChange}
                  className="border rounded w-full py-2 px-3 text-gray-700"
                  rows="4"
                />
                <label htmlFor="subject" className="profileheadline">
                  Subject
                </label>
              </div>
            </div>
            {/* <div className="">
              <div className="relative job-search-label-section singleselect2">
                <Select2
                  id="currentJobStatus"
                  className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
                  name="currentJobStatus"
                  multiple={false}
                  defaultValue={formData.currentJobStatus}
                  onChange={(e) => handleSelectChange(e, "currentJobStatus")}
                  data={currentJobStatusOptions.map((status) => ({
                    id: status.value,
                    text: status.label,
                  }))}
                  options={{
                    placeholder: "",
                    theme: "classic",
                    width: "resolve",
                  }}
                />
                <label
                  htmlFor="currentJobStatus"
                  className="block text-sm font-medium text-gray-700 multipleselectlabelskill"
                >
                  Current Job Status
                </label>
              </div>
            </div> */}
          </div>

          <div className="form-group">
            <textarea
              id="description"
              placeholder=""
              value={formData.description}
              onChange={handleChange}
              className="border rounded w-full py-2 px-3 text-gray-700 custom-scrollbar"
              rows="4"
            />
            <label htmlFor="description" className="profileheadline">
              Description
            </label>
          </div>

          <div className="flex justify-end mt-6">
            <button type="submit" className="Job-Post-btn text-white">
              Save
            </button>
          </div>
        </form>
      </div>{" "}
      <ToastContainer />
    </div>
  );
};

export default TicketForm;
