import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import "../Layout/menubaar.css";
// import dashboard from "../../image/dashboard-plus.png";
import FeatherIcon from "feather-icons-react";
import { usePopup } from "../Common/PopupContext";
// import DeleteAccount from "../Empolyers/DeleteAccount";
import { getUserData } from "../../helpers/utils";
import CourseIcon from "../../img/course_icon.png";
import api from "../../Api";
const User = getUserData();

const Menubar = () => {
  const [activeLink, setActiveLink] = useState("");
  const location = useLocation(); // React Router's hook to get the current location
  const User = getUserData();
  useEffect(() => {
    const pathname = location.pathname;

    // Normalize the pathname
    const normalizedPathname = pathname.endsWith("/")
      ? pathname.slice(0, -1)
      : pathname;

    // Set the active link based on the current pathname
    setActiveLink(normalizedPathname);
  }, [location.pathname]); // Run this effect when location.pathname changes

  const handleLinkClick = (linkName) => {
    // Normalization is handled in the effect
    const normalizedLinkName = linkName.endsWith("/")
      ? linkName.slice(0, -1)
      : linkName;

    setActiveLink(normalizedLinkName);
  };

  const toggleSubMenu = (e) => {
    let arrowParent = e.currentTarget.parentElement; // Selecting parent element of arrow
    arrowParent.classList.toggle("showMenu");
  };
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  /* ======= For Popup ====== */
  const { openPopup } = usePopup();
  const [popupComponent, setPopupComponent] = useState(null);

  const handleClosePopup = (message) => {
    console.log(message);
    setPopupComponent(null);
    openPopup();
  };

  const DeleteAccountAction = () => {
    const popup =
      // <DeleteAccount onClose={() => handleClosePopup("Popup closed")} />
      "";
    setPopupComponent(popup);
    openPopup(popup);
  };
  /* ======= Ends Popup ====== */
  const [SettingSubMenuOpen, setSettingSubMenuOpen] = useState();
  const togglesettingSubMenu = () => {
    setSettingSubMenuOpen(!SettingSubMenuOpen);
  };
  return (
    <>
      <div className={`sidebar ${sidebarOpen ? "open" : "close"}`}>
        <div className="cross-icon-btn" onClick={toggleSidebar}>
          <FeatherIcon icon="chevron-left" color="#fff" size={24} />
        </div>
        <div className="plus-icon-btn" onClick={toggleSidebar}>
          <FeatherIcon icon="chevron-right" color="#fff" size={24} />
        </div>
        <div className="main-continor">
          <ul className="nav-links">
            <li
              className={`dashboard-menu-icon ${
                activeLink === "/dashboard" ? "nav-active" : ""
              }`}
            >
              <Link
                to="/dashboard"
                onClick={() => handleLinkClick("/dashboard")}
              >
                <svg
                  width="34"
                  height="40"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.1225 19.1247H6.63287C5.90555 19.1288 5.20941 19.4206 4.69657 19.9364C4.18373 20.4521 3.89586 21.1499 3.89587 21.8773V27.3668C3.89587 28.0927 4.18424 28.7889 4.69752 29.3022C5.21081 29.8155 5.90698 30.1038 6.63287 30.1038H12.1225C12.8497 30.1039 13.5473 29.8159 14.0628 29.3031C14.5784 28.7902 14.8699 28.094 14.8736 27.3668V21.8773C14.8736 21.1476 14.5838 20.4478 14.0678 19.9319C13.5519 19.4159 12.8521 19.1261 12.1225 19.1261M12.1225 3.89551H6.63287C6.27345 3.89551 5.91754 3.9663 5.58547 4.10385C5.2534 4.2414 4.95168 4.443 4.69752 4.69716C4.44337 4.95131 4.24176 5.25304 4.10422 5.5851C3.96667 5.91717 3.89587 6.27308 3.89587 6.63251V12.1221C3.89586 12.8493 4.18377 13.5469 4.69666 14.0625C5.20954 14.578 5.90568 14.8695 6.63287 14.8733H12.1225C12.8521 14.8733 13.5519 14.5834 14.0678 14.0675C14.5838 13.5515 14.8736 12.8517 14.8736 12.1221V6.63251C14.8699 5.90531 14.5784 5.20917 14.0628 4.69629C13.5473 4.18341 12.8497 3.8955 12.1225 3.89551ZM27.3672 3.89551H21.8776C21.1504 3.8955 20.4528 4.18341 19.9372 4.69629C19.4217 5.20917 19.1302 5.90531 19.1265 6.63251V12.1221C19.1268 12.8516 19.4168 13.5512 19.9327 14.067C20.4485 14.5829 21.1481 14.8729 21.8776 14.8733H27.3672C28.0944 14.8695 28.7905 14.578 29.3034 14.0625C29.8163 13.5469 30.1042 12.8493 30.1042 12.1221V6.63251C30.1042 5.90661 29.8158 5.21044 29.3026 4.69716C28.7893 4.18387 28.0931 3.89551 27.3672 3.89551Z"
                    stroke="#000"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="path-icon"
                  />
                  <path
                    d="M24.6047 19.5742V29.6552M19.5741 24.6261H29.6551"
                    // stroke="#000"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    className="path-icon"
                  />
                </svg>
                <span className="link_name">Dashboard</span>
              </Link>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="/dashboard">
                    Dashboard
                  </a>
                </li>
              </ul>
            </li>

            <li
              className={`dashboard-menu-icon ${
                activeLink === "/dashboard/messages" ? "nav-active" : ""
              }`}
            >
              <Link
                to="/dashboard/messages"
                onClick={() => handleLinkClick("/dashboard/messages")}
              >
                <div>
                  <svg
                    className="icon"
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_69_22)">
                      <path
                        d="M3.92308 5.23047C1.75754 5.23047 0 6.98801 0 9.15355V24.8459C0 27.0114 1.75754 28.7689 3.92308 28.7689H30.0769C32.2425 28.7689 34 27.0114 34 24.8459V9.15355C34 6.98801 32.2425 5.23047 30.0769 5.23047H3.92308ZM3.92308 7.84585H30.0769C30.7975 7.84585 31.3846 8.43301 31.3846 9.15355V9.80739L17 17.5725L2.61538 9.80739V9.15355C2.61538 8.43301 3.20254 7.84585 3.92308 7.84585ZM2.61538 10.1749L11.1559 16.8362L2.77885 25.4186L12.9958 18.1439L17 20.7188L21.0055 18.1439L31.2212 25.4186L22.8441 16.8362L31.3846 10.1749V24.8459C31.3774 25.0472 31.3213 25.2438 31.2212 25.4186C31.0054 25.8436 30.5856 26.1535 30.0769 26.1535H3.92308C3.41438 26.1535 2.99462 25.8449 2.77885 25.4186C2.67892 25.2442 2.62283 25.0468 2.61538 24.8459V10.1749Z"
                        className="icon-path"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_69_22">
                        <rect width="34" height="34" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <span className="link_name">Messages</span>
              </Link>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="/dashboard/messages">
                    Messages
                  </a>
                </li>
              </ul>
            </li>

            <li
              className={`dashboard-menu-icon ${
                activeLink === "/dashboard/chat" ? "nav-active" : ""
              }`}
            >
              <Link
                to="/dashboard/chat"
                onClick={() => handleLinkClick("/dashboard/chat")}
              >
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="#000"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M34 27.625V25.5H31.7677C31.6298 24.8351 31.3658 24.2029 30.9899 23.6374L32.5731 22.0543L31.0707 20.5519L29.4876 22.1351C28.9221 21.7592 28.2899 21.4952 27.625 21.3573V19.125H25.5V21.3573C24.8351 21.4952 24.2029 21.7592 23.6374 22.1351L22.0543 20.5519L20.5519 22.0543L22.1351 23.6374C21.7592 24.2029 21.4952 24.8351 21.3573 25.5H19.125V27.625H21.3573C21.4954 28.3018 21.7653 28.9308 22.1351 29.4876L20.5519 31.0707L22.0543 32.5731L23.6374 30.9899C24.2029 31.3658 24.8351 31.6298 25.5 31.7677V34H27.625V31.7677C28.2899 31.6298 28.9221 31.3658 29.4876 30.9899L31.0707 32.5731L32.5731 31.0707L30.9899 29.4876C31.3658 28.9221 31.6298 28.2899 31.7677 27.625H34ZM26.5625 29.75C24.8051 29.75 23.375 28.3199 23.375 26.5625C23.375 24.8051 24.8051 23.375 26.5625 23.375C28.3199 23.375 29.75 24.8051 29.75 26.5625C29.75 28.3199 28.3199 29.75 26.5625 29.75ZM21.25 18.0625H12.75C12.1864 18.0625 11.6459 17.8386 11.2474 17.4401C10.8489 17.0416 10.625 16.5011 10.625 15.9375V4.25C10.625 3.68641 10.8489 3.14591 11.2474 2.7474C11.6459 2.34888 12.1864 2.125 12.75 2.125H21.25C21.8136 2.125 22.3541 2.34888 22.7526 2.7474C23.1511 3.14591 23.375 3.68641 23.375 4.25V15.9375C23.375 16.5011 23.1511 17.0416 22.7526 17.4401C22.3541 17.8386 21.8136 18.0625 21.25 18.0625ZM12.75 15.9375H21.25V4.25H12.75V15.9375Z"
                    // fill="#000"
                    className="path-icon"
                  />
                  <path
                    d="M18.0625 22.3125H8.5C7.93641 22.3125 7.39591 22.0886 6.9974 21.6901C6.59888 21.2916 6.375 20.7511 6.375 20.1875V7.4375H8.5V20.1875H18.0625V22.3125Z"
                    // fill="#000"
                    className="path-icon"
                  />
                  <path
                    d="M13.8125 26.5625H4.25C3.07806 26.5625 2.125 25.6094 2.125 24.4375V11.6875H4.25V24.4375H13.8125V26.5625Z"
                    // fill="#000"
                    className="path-icon"
                  />
                </svg>

                <span className="link_name">Chatbot Prompt</span>
              </Link>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="/dashboard/chat">
                    Chatbot Prompt
                  </a>
                </li>
              </ul>
            </li>
            <li
              className={`dashboard-menu-icon ${
                activeLink === "/dashboard/custom-setting" ? "nav-active" : ""
              }`}
            >
              <Link
                to="/dashboard/custom-setting"
                onClick={() => handleLinkClick("/dashboard/custom-setting")}
              >
                <svg
                  width="34"
                  height="34"
                  fill="#000"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
                </svg>
                <span className="link_name"> Custom chat setting</span>
              </Link>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="/dashboard/custom-setting">
                    Customchatsetting
                  </a>
                </li>
              </ul>
            </li>
            <li
              className={`dashboard-menu-icon ${
                activeLink === "/dashboard/integrate" ? "nav-active" : ""
              }`}
            >
              <Link
                to="/dashboard/integrate"
                onClick={() => handleLinkClick("/dashboard/integrate")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  width="34"
                  height="34"
                  fill="#000"
                >
                  <path d="M78.6 5C69.1-2.4 55.6-1.5 47 7L7 47c-8.5 8.5-9.4 22-2.1 31.6l80 104c4.5 5.9 11.6 9.4 19 9.4l54.1 0 109 109c-14.7 29-10 65.4 14.3 89.6l112 112c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-112-112c-24.2-24.2-60.6-29-89.6-14.3l-109-109 0-54.1c0-7.5-3.5-14.5-9.4-19L78.6 5zM19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L233.7 374.3c-7.8-20.9-9-43.6-3.6-65.1l-61.7-61.7L19.9 396.1zM512 144c0-10.5-1.1-20.7-3.2-30.5c-2.4-11.2-16.1-14.1-24.2-6l-63.9 63.9c-3 3-7.1 4.7-11.3 4.7L352 176c-8.8 0-16-7.2-16-16l0-57.4c0-4.2 1.7-8.3 4.7-11.3l63.9-63.9c8.1-8.1 5.2-21.8-6-24.2C388.7 1.1 378.5 0 368 0C288.5 0 224 64.5 224 144l0 .8 85.3 85.3c36-9.1 75.8 .5 104 28.7L429 274.5c49-23 83-72.8 83-130.5zM56 432a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z" />
                </svg>

                <span className="link_name">Integrate</span>
              </Link>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="/dashboard/integrate">
                    Integrate
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const SuperAdmin = () => {
  const [activeLink, setActiveLink] = useState("");
  const location = useLocation(); // React Router's hook to get the current location
  const User = getUserData();
  useEffect(() => {
    const pathname = location.pathname;

    // Normalize the pathname
    const normalizedPathname = pathname.endsWith("/")
      ? pathname.slice(0, -1)
      : pathname;

    // Set the active link based on the current pathname
    setActiveLink(normalizedPathname);
  }, [location.pathname]); // Run this effect when location.pathname changes

  const handleLinkClick = (linkName) => {
    // Normalization is handled in the effect
    const normalizedLinkName = linkName.endsWith("/")
      ? linkName.slice(0, -1)
      : linkName;

    setActiveLink(normalizedLinkName);
  };

  const toggleSubMenu = (e) => {
    let arrowParent = e.currentTarget.parentElement; // Selecting parent element of arrow
    arrowParent.classList.toggle("showMenu");
  };
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  /* ======= For Popup ====== */
  const { openPopup } = usePopup();
  const [popupComponent, setPopupComponent] = useState(null);

  const handleClosePopup = (message) => {
    console.log(message);
    setPopupComponent(null);
    openPopup();
  };

  const DeleteAccountAction = () => {
    const popup =
      // <DeleteAccount onClose={() => handleClosePopup("Popup closed")} />
      "";
    setPopupComponent(popup);
    openPopup(popup);
  };
  /* ======= Ends Popup ====== */
  const [SettingSubMenuOpen, setSettingSubMenuOpen] = useState();
  const togglesettingSubMenu = () => {
    setSettingSubMenuOpen(!SettingSubMenuOpen);
  };
  return (
    <>
      <div className={`sidebar ${sidebarOpen ? "open" : "close"}`}>
        <div className="cross-icon-btn" onClick={toggleSidebar}>
          <FeatherIcon icon="chevron-left" color="#fff" size={24} />
        </div>
        <div className="plus-icon-btn" onClick={toggleSidebar}>
          <FeatherIcon icon="chevron-right" color="#fff" size={24} />
        </div>
        <div className="main-continor">
          <ul className="nav-links">
            <li
              className={`dashboard-menu-icon ${
                activeLink === "/dashboard" ? "nav-active" : ""
              }`}
            >
              <Link
                to="/dashboard"
                onClick={() => handleLinkClick("/dashboard")}
              >
                <svg
                  width="34"
                  height="40"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.1225 19.1247H6.63287C5.90555 19.1288 5.20941 19.4206 4.69657 19.9364C4.18373 20.4521 3.89586 21.1499 3.89587 21.8773V27.3668C3.89587 28.0927 4.18424 28.7889 4.69752 29.3022C5.21081 29.8155 5.90698 30.1038 6.63287 30.1038H12.1225C12.8497 30.1039 13.5473 29.8159 14.0628 29.3031C14.5784 28.7902 14.8699 28.094 14.8736 27.3668V21.8773C14.8736 21.1476 14.5838 20.4478 14.0678 19.9319C13.5519 19.4159 12.8521 19.1261 12.1225 19.1261M12.1225 3.89551H6.63287C6.27345 3.89551 5.91754 3.9663 5.58547 4.10385C5.2534 4.2414 4.95168 4.443 4.69752 4.69716C4.44337 4.95131 4.24176 5.25304 4.10422 5.5851C3.96667 5.91717 3.89587 6.27308 3.89587 6.63251V12.1221C3.89586 12.8493 4.18377 13.5469 4.69666 14.0625C5.20954 14.578 5.90568 14.8695 6.63287 14.8733H12.1225C12.8521 14.8733 13.5519 14.5834 14.0678 14.0675C14.5838 13.5515 14.8736 12.8517 14.8736 12.1221V6.63251C14.8699 5.90531 14.5784 5.20917 14.0628 4.69629C13.5473 4.18341 12.8497 3.8955 12.1225 3.89551ZM27.3672 3.89551H21.8776C21.1504 3.8955 20.4528 4.18341 19.9372 4.69629C19.4217 5.20917 19.1302 5.90531 19.1265 6.63251V12.1221C19.1268 12.8516 19.4168 13.5512 19.9327 14.067C20.4485 14.5829 21.1481 14.8729 21.8776 14.8733H27.3672C28.0944 14.8695 28.7905 14.578 29.3034 14.0625C29.8163 13.5469 30.1042 12.8493 30.1042 12.1221V6.63251C30.1042 5.90661 29.8158 5.21044 29.3026 4.69716C28.7893 4.18387 28.0931 3.89551 27.3672 3.89551Z"
                    stroke="#000"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="path-icon"
                  />
                  <path
                    d="M24.6047 19.5742V29.6552M19.5741 24.6261H29.6551"
                    // stroke="#000"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    className="path-icon"
                  />
                </svg>
                <span className="link_name">Dashboard</span>
              </Link>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="/dashboard">
                    Dashboard
                  </a>
                </li>
              </ul>
            </li>
            <li
              className={`dashboard-menu-icon ${
                activeLink === "/dashboard/leads" ? "nav-active" : ""
              }`}
            >
              <Link
                to="/dashboard/leads"
                onClick={() => handleLinkClick("/dashboard/leads")}
              >
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="#000"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M34 27.625V25.5H31.7677C31.6298 24.8351 31.3658 24.2029 30.9899 23.6374L32.5731 22.0543L31.0707 20.5519L29.4876 22.1351C28.9221 21.7592 28.2899 21.4952 27.625 21.3573V19.125H25.5V21.3573C24.8351 21.4952 24.2029 21.7592 23.6374 22.1351L22.0543 20.5519L20.5519 22.0543L22.1351 23.6374C21.7592 24.2029 21.4952 24.8351 21.3573 25.5H19.125V27.625H21.3573C21.4954 28.3018 21.7653 28.9308 22.1351 29.4876L20.5519 31.0707L22.0543 32.5731L23.6374 30.9899C24.2029 31.3658 24.8351 31.6298 25.5 31.7677V34H27.625V31.7677C28.2899 31.6298 28.9221 31.3658 29.4876 30.9899L31.0707 32.5731L32.5731 31.0707L30.9899 29.4876C31.3658 28.9221 31.6298 28.2899 31.7677 27.625H34ZM26.5625 29.75C24.8051 29.75 23.375 28.3199 23.375 26.5625C23.375 24.8051 24.8051 23.375 26.5625 23.375C28.3199 23.375 29.75 24.8051 29.75 26.5625C29.75 28.3199 28.3199 29.75 26.5625 29.75ZM21.25 18.0625H12.75C12.1864 18.0625 11.6459 17.8386 11.2474 17.4401C10.8489 17.0416 10.625 16.5011 10.625 15.9375V4.25C10.625 3.68641 10.8489 3.14591 11.2474 2.7474C11.6459 2.34888 12.1864 2.125 12.75 2.125H21.25C21.8136 2.125 22.3541 2.34888 22.7526 2.7474C23.1511 3.14591 23.375 3.68641 23.375 4.25V15.9375C23.375 16.5011 23.1511 17.0416 22.7526 17.4401C22.3541 17.8386 21.8136 18.0625 21.25 18.0625ZM12.75 15.9375H21.25V4.25H12.75V15.9375Z"
                    // fill="#000"
                    className="path-icon"
                  />
                  <path
                    d="M18.0625 22.3125H8.5C7.93641 22.3125 7.39591 22.0886 6.9974 21.6901C6.59888 21.2916 6.375 20.7511 6.375 20.1875V7.4375H8.5V20.1875H18.0625V22.3125Z"
                    // fill="#000"
                    className="path-icon"
                  />
                  <path
                    d="M13.8125 26.5625H4.25C3.07806 26.5625 2.125 25.6094 2.125 24.4375V11.6875H4.25V24.4375H13.8125V26.5625Z"
                    // fill="#000"
                    className="path-icon"
                  />
                </svg>

                <span className="link_name"> Clients</span>
              </Link>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="/dashboard/jobs">
                    Clients
                  </a>
                </li>
              </ul>
            </li>
            <li
              className={`dashboard-menu-icon ${
                activeLink === "/dashboard/messages" ? "nav-active" : ""
              }`}
            >
              <Link
                to="/dashboard/messages"
                onClick={() => handleLinkClick("/dashboard/messages")}
              >
                <div>
                  <svg
                    className="icon"
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_69_22)">
                      <path
                        d="M3.92308 5.23047C1.75754 5.23047 0 6.98801 0 9.15355V24.8459C0 27.0114 1.75754 28.7689 3.92308 28.7689H30.0769C32.2425 28.7689 34 27.0114 34 24.8459V9.15355C34 6.98801 32.2425 5.23047 30.0769 5.23047H3.92308ZM3.92308 7.84585H30.0769C30.7975 7.84585 31.3846 8.43301 31.3846 9.15355V9.80739L17 17.5725L2.61538 9.80739V9.15355C2.61538 8.43301 3.20254 7.84585 3.92308 7.84585ZM2.61538 10.1749L11.1559 16.8362L2.77885 25.4186L12.9958 18.1439L17 20.7188L21.0055 18.1439L31.2212 25.4186L22.8441 16.8362L31.3846 10.1749V24.8459C31.3774 25.0472 31.3213 25.2438 31.2212 25.4186C31.0054 25.8436 30.5856 26.1535 30.0769 26.1535H3.92308C3.41438 26.1535 2.99462 25.8449 2.77885 25.4186C2.67892 25.2442 2.62283 25.0468 2.61538 24.8459V10.1749Z"
                        className="icon-path"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_69_22">
                        <rect width="34" height="34" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <span className="link_name">Messages</span>
              </Link>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="/dashboard/messages">
                    Messages
                  </a>
                </li>
              </ul>
            </li>

            <li
              className={`dashboard-menu-icon ${
                activeLink === "/dashboard/chat" ? "nav-active" : ""
              }`}
            >
              <Link
                to="/dashboard/chat"
                onClick={() => handleLinkClick("/dashboard/chat")}
              >
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="#000"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M34 27.625V25.5H31.7677C31.6298 24.8351 31.3658 24.2029 30.9899 23.6374L32.5731 22.0543L31.0707 20.5519L29.4876 22.1351C28.9221 21.7592 28.2899 21.4952 27.625 21.3573V19.125H25.5V21.3573C24.8351 21.4952 24.2029 21.7592 23.6374 22.1351L22.0543 20.5519L20.5519 22.0543L22.1351 23.6374C21.7592 24.2029 21.4952 24.8351 21.3573 25.5H19.125V27.625H21.3573C21.4954 28.3018 21.7653 28.9308 22.1351 29.4876L20.5519 31.0707L22.0543 32.5731L23.6374 30.9899C24.2029 31.3658 24.8351 31.6298 25.5 31.7677V34H27.625V31.7677C28.2899 31.6298 28.9221 31.3658 29.4876 30.9899L31.0707 32.5731L32.5731 31.0707L30.9899 29.4876C31.3658 28.9221 31.6298 28.2899 31.7677 27.625H34ZM26.5625 29.75C24.8051 29.75 23.375 28.3199 23.375 26.5625C23.375 24.8051 24.8051 23.375 26.5625 23.375C28.3199 23.375 29.75 24.8051 29.75 26.5625C29.75 28.3199 28.3199 29.75 26.5625 29.75ZM21.25 18.0625H12.75C12.1864 18.0625 11.6459 17.8386 11.2474 17.4401C10.8489 17.0416 10.625 16.5011 10.625 15.9375V4.25C10.625 3.68641 10.8489 3.14591 11.2474 2.7474C11.6459 2.34888 12.1864 2.125 12.75 2.125H21.25C21.8136 2.125 22.3541 2.34888 22.7526 2.7474C23.1511 3.14591 23.375 3.68641 23.375 4.25V15.9375C23.375 16.5011 23.1511 17.0416 22.7526 17.4401C22.3541 17.8386 21.8136 18.0625 21.25 18.0625ZM12.75 15.9375H21.25V4.25H12.75V15.9375Z"
                    // fill="#000"
                    className="path-icon"
                  />
                  <path
                    d="M18.0625 22.3125H8.5C7.93641 22.3125 7.39591 22.0886 6.9974 21.6901C6.59888 21.2916 6.375 20.7511 6.375 20.1875V7.4375H8.5V20.1875H18.0625V22.3125Z"
                    // fill="#000"
                    className="path-icon"
                  />
                  <path
                    d="M13.8125 26.5625H4.25C3.07806 26.5625 2.125 25.6094 2.125 24.4375V11.6875H4.25V24.4375H13.8125V26.5625Z"
                    // fill="#000"
                    className="path-icon"
                  />
                </svg>

                <span className="link_name">Chatbot Prompt</span>
              </Link>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="/dashboard/chat">
                    Chatbot Prompt
                  </a>
                </li>
              </ul>
            </li>
            <li
              className={`dashboard-menu-icon ${
                activeLink === "/dashboard/custom-setting" ? "nav-active" : ""
              }`}
            >
              <Link
                to="/dashboard/custom-setting"
                onClick={() => handleLinkClick("/dashboard/custom-setting")}
              >
                {/* <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="#000"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M34 27.625V25.5H31.7677C31.6298 24.8351 31.3658 24.2029 30.9899 23.6374L32.5731 22.0543L31.0707 20.5519L29.4876 22.1351C28.9221 21.7592 28.2899 21.4952 27.625 21.3573V19.125H25.5V21.3573C24.8351 21.4952 24.2029 21.7592 23.6374 22.1351L22.0543 20.5519L20.5519 22.0543L22.1351 23.6374C21.7592 24.2029 21.4952 24.8351 21.3573 25.5H19.125V27.625H21.3573C21.4954 28.3018 21.7653 28.9308 22.1351 29.4876L20.5519 31.0707L22.0543 32.5731L23.6374 30.9899C24.2029 31.3658 24.8351 31.6298 25.5 31.7677V34H27.625V31.7677C28.2899 31.6298 28.9221 31.3658 29.4876 30.9899L31.0707 32.5731L32.5731 31.0707L30.9899 29.4876C31.3658 28.9221 31.6298 28.2899 31.7677 27.625H34ZM26.5625 29.75C24.8051 29.75 23.375 28.3199 23.375 26.5625C23.375 24.8051 24.8051 23.375 26.5625 23.375C28.3199 23.375 29.75 24.8051 29.75 26.5625C29.75 28.3199 28.3199 29.75 26.5625 29.75ZM21.25 18.0625H12.75C12.1864 18.0625 11.6459 17.8386 11.2474 17.4401C10.8489 17.0416 10.625 16.5011 10.625 15.9375V4.25C10.625 3.68641 10.8489 3.14591 11.2474 2.7474C11.6459 2.34888 12.1864 2.125 12.75 2.125H21.25C21.8136 2.125 22.3541 2.34888 22.7526 2.7474C23.1511 3.14591 23.375 3.68641 23.375 4.25V15.9375C23.375 16.5011 23.1511 17.0416 22.7526 17.4401C22.3541 17.8386 21.8136 18.0625 21.25 18.0625ZM12.75 15.9375H21.25V4.25H12.75V15.9375Z"
                    
                    className="path-icon"
                  />
                  <path
                    d="M18.0625 22.3125H8.5C7.93641 22.3125 7.39591 22.0886 6.9974 21.6901C6.59888 21.2916 6.375 20.7511 6.375 20.1875V7.4375H8.5V20.1875H18.0625V22.3125Z"
                   
                    className="path-icon"
                  />
                  <path
                    d="M13.8125 26.5625H4.25C3.07806 26.5625 2.125 25.6094 2.125 24.4375V11.6875H4.25V24.4375H13.8125V26.5625Z"
                  
                    className="path-icon"
                  />
                </svg> */}
                <svg
                  width="34"
                  height="34"
                  fill="#000"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
                </svg>

                <span className="link_name"> Custom chat setting</span>
              </Link>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="/dashboard/custom-setting">
                    Customchatsetting
                  </a>
                </li>
              </ul>
            </li>
            <li
              className={`dashboard-menu-icon ${
                activeLink === "/dashboard/integrate" ? "nav-active" : ""
              }`}
            >
              <Link
                to="/dashboard/integrate"
                onClick={() => handleLinkClick("/dashboard/integrate")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  width="34"
                  height="34"
                  fill="#000"
                >
                  <path d="M78.6 5C69.1-2.4 55.6-1.5 47 7L7 47c-8.5 8.5-9.4 22-2.1 31.6l80 104c4.5 5.9 11.6 9.4 19 9.4l54.1 0 109 109c-14.7 29-10 65.4 14.3 89.6l112 112c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-112-112c-24.2-24.2-60.6-29-89.6-14.3l-109-109 0-54.1c0-7.5-3.5-14.5-9.4-19L78.6 5zM19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L233.7 374.3c-7.8-20.9-9-43.6-3.6-65.1l-61.7-61.7L19.9 396.1zM512 144c0-10.5-1.1-20.7-3.2-30.5c-2.4-11.2-16.1-14.1-24.2-6l-63.9 63.9c-3 3-7.1 4.7-11.3 4.7L352 176c-8.8 0-16-7.2-16-16l0-57.4c0-4.2 1.7-8.3 4.7-11.3l63.9-63.9c8.1-8.1 5.2-21.8-6-24.2C388.7 1.1 378.5 0 368 0C288.5 0 224 64.5 224 144l0 .8 85.3 85.3c36-9.1 75.8 .5 104 28.7L429 274.5c49-23 83-72.8 83-130.5zM56 432a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z" />
                </svg>

                <span className="link_name">Integrate</span>
              </Link>
              <ul className="sub-menu blank">
                <li>
                  <a className="link_name" href="/dashboard/integrate">
                    Integrate
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export { SuperAdmin, Menubar };
