import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import ChatList from "../../helpers/ChatList";
import MessagePanel from "../../helpers/MessagePanel";
import { io } from "socket.io-client";
import api from "../../Api";
import { getUserData } from "../../helpers/utils";

const Messages = () => {
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messageList, setMessageList] = useState([]);
  const scrollRef = useRef(null);
  const [socket, setSocket] = useState(null);
  const [hasFetched, setHasFetched] = useState(false); // Prevent repeated fetches
  const User = getUserData();

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  const handleSelectChat = (chat) => {
    setSelectedChat(chat);
    if (chat.applicationId && socket) {
      socket.emit("joinRoom", { applicationId: chat.applicationId });
      loadMessages(chat.applicationId);
    }
  };

  const loadMessages = async (applicationId) => {
    try {
      const response = await api.getMessagesByApplications(applicationId);
      if (response.status === "success") {
        const participants = response?.participants || [];

        const messages = response?.messages.map((msg, index) => {
          const sender = participants.find(
            (participant) => participant._id === msg.senderId
          );
          return {
            user: sender ? sender.name : "Unknown Sender",
            text: msg.message,
            timestamp: moment(msg.createdAt).format("hh:mm A"),
            key: index,
          };
        });

        setMessageList(messages);
        scrollToBottom();
      } else {
        console.error("Failed to load messages:", response.message);
      }
    } catch (error) {
      console.error("Error loading messages:", error);
    }
  };
  const handleSendMessage = (text) => {
    if (!selectedChat || text.trim() === "" || !socket) return;

    if (!selectedChat.applicationId) {
      console.error("Error: No applicationId available for this chat.");
      return;
    }

    const sender = selectedChat.participants.find(
      (participant) => participant._id === User?._id
    );

    const newMessage = {
      user: sender ? sender.name : "Unknown Sender",
      applicationId: selectedChat.applicationId,
      text,
      timestamp: moment().format("hh:mm A"),
    };

    socket.emit(
      "sendMessage",
      {
        applicationId: selectedChat.applicationId,
        senderId: User._id,
        message: text,
        
      },
      (acknowledgment) => {
        if (acknowledgment?.status === "success") {
          setMessageList((prevMessages) => [...prevMessages, newMessage]);
          scrollToBottom();
        } else {
          console.error("Message send failed:", acknowledgment?.message);
        }
      }
    );
  };
  useEffect(() => {
    if (!User?._id || hasFetched) return; // Prevent repeated calls

    console.count("Fetching messages API called");

    const getMessages = async () => {
      try {
        const response = await api.getMessagesByUser(User._id);
        console.log("API Response:", response);

        if (response.success) {
          const chatList = [
            {
              userId: response.user.userId,
              userName: response.user.userName || "Unknown User",
              messages: response.user.messages || [],
              lastMessage:
                response.user.messages?.[response.user.messages.length - 1]
                  ?.content || "No messages yet",
            },
          ];
          setChats(chatList);
        } else {
          console.error("Error in response:", response.message);
          setChats([]);
        }
        setHasFetched(true); // Set the flag to true after fetching
      } catch (error) {
        console.error("Failed to fetch chats:", error.message);
        setChats([]);
      }
    };

    getMessages();
  }, [User, hasFetched]);

  useEffect(() => {
    if (User && !socket) {
      const newSocket = io("http://89.117.144.11:5001", {
        transports: ["websocket"],
      });

      setSocket(newSocket);

      return () => {
        newSocket.disconnect();
      };
    }
  }, [User]);

  useEffect(() => {
    if (socket && selectedChat) {
      const handleReceiveMessage = (data) => {
        if (data.applicationId === selectedChat.applicationId) {
          setMessageList((prevMessages) => [
            ...prevMessages,
            {
              user: data.senderName || "Unknown Sender",
              text: data.message,
              timestamp: moment(data.createdAt).format("hh:mm A"),
            },
          ]);
          scrollToBottom();
        }
      };

      socket.on("receiveMessage", handleReceiveMessage);

      return () => {
        socket.off("receiveMessage", handleReceiveMessage);
      };
    }
  }, [socket, selectedChat]);

  return (
    <div className="flex main-chat-panel">
      <div className="w-1/3 border-r overflow-y-auto">
        {console.log(chats)}
        <ChatList chats={chats} onSelectChat={handleSelectChat} />
      </div>
      <div className="w-2/3 flex flex-col">
        {selectedChat ? (
          <div className="flex-1 overflow-y-auto p-4" ref={scrollRef}>
            <MessagePanel
              selectedChat={selectedChat}
              onSendMessage={handleSendMessage}
              messageList={messageList}
            />
          </div>
        ) : (
          <div className="flex-1 p-4 flex items-center justify-center">
            <h2 className="text-gray-400 text-lg">
              Select a chat to start messaging
            </h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default Messages;
