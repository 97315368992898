import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { getUserData } from "../../helpers/utils";
import Select2 from "react-select2-wrapper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Api";
import FeatherIcon from "feather-icons-react";
import default123 from "../../image/DefaultImage.png";

const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;

const ManageBlogs = () => {
  const history = useHistory();
  const User = getUserData();
  const [render, setRender] = useState(0);
  const [data, setdata] = useState([]);

  const handleDelete = async (id) => {
    const response = await api.deleteBlog(`${id}`);
    if (response.status === 200) {
      toast.success(response.msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setRender((prev) => prev + 1);
    } else {
      toast.error("There was an error deleting the blog!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const status = [
    { id: "draft", text: "Draft" },
    { id: "published", text: "Published" },
    { id: "archived", text: "Archived" },
  ];

  const handleUpdateStatus = async (e, id) => {
    e.preventDefault();
    const formdata = { status: e.target.value };

    try {
      const res = await api.updateBlogstatus(id, formdata);
      if (res.status === 200) {
        toast.success("Status Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      toast.error("Error updating status", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const columns = [
    {
      name: "Image",
      selector: (row) =>
        row?.featured_image && row?.featured_image !== "null" ? (
          <img
            className="image"
            width={60}
            height={60}
            src={`${domainpath}/uploads/${row.featured_image}`}
            alt="Featured"
          />
        ) : (
          <img
            className="image"
            width={60}
            height={60}
            src={default123}
            alt="Default"
          />
        ),
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => (
        <a
          href={`https://ChatPro.co.uk/blogs/${row.slug}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {row.title}
        </a>
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <div className="relative job-search-label-section singleselect2 statusenroll">
          <Select2
            data={status}
            className="jobsearchsector p-1 mr-2 w-full no-border appearance-none text-base/8"
            defaultValue={row.status}
            onChange={(e) => handleUpdateStatus(e, row._id)}
            options={{
              placeholder: "Select Status",
              width: "100%",
              theme: "classic",
            }}
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-1">
          <button
            className="manage-edit-btn text-white px-6 py-2"
            onClick={() => history.push(`./edit-blog/${row._id}`)}
          >
            <FeatherIcon icon="edit-2" />
          </button>
          <button
            className="manage-delete-btn text-white px-6 py-2"
            onClick={() => handleDelete(row._id)}
          >
            <FeatherIcon icon="trash" />
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    async function getBlogs() {
      try {
        const response = await api.getBlogs();
        if (response.status === 200) {
          setdata(response.blogs);
        }
      } catch (error) {
        console.error("Error fetching blogs", error);
      }
    }
    getBlogs();
  }, [render]);

  const tableData = {
    columns,
    data,
  };

  return (
    <div className="py-8">
      <div className="bg-white p-4 rounded-lg shadow">
        <div className="flex justify-between">
          <h2 className="text-lg font-bold mb-4" style={{ color: "#000" }}>
            Manage Blogs
          </h2>
          <button
            className="manage-add-btn text-white px-3 py-1 Job-Post-btn"
            onClick={() => history.push(`./add-blog`)}
          >
            Add Blogs
          </button>
        </div>
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={data}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ManageBlogs;
