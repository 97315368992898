import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import api from "../../Api";
const AdminDashboard = () => {
  const [Data, setData] = useState([]);
  const columns = [
    {
      name: "Activity",
      selector: (row) => row.activity,
      sortable: true,
    },
    {
      name: "User",
      selector: (row) => row.user,
      sortable: true,
    },
    {
      name: "Time",
      selector: (row) => row.time,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <button className="manage-edit-btn text-white px-4 py-2 rounded-full">
          View Details
        </button>
      ),
    },
  ];

  const data = [
    {
      id: 1,
      activity: "Posted a Job",
      user: "Tech Solutions",
      time: "2024-06-01 10:00 AM",
    },
    {
      id: 2,
      activity: "Applied for a Job",
      user: "Marketing Hub",
      time: "2024-06-01 11:00 AM",
    },
  ];

  const tableData = {
    columns,
    data,
  };
  useEffect(() => {
    async function getDashboard() {
      try {
        const response = await api.getSuperAdmindashboard();

        if (response.status !== 400) {
          const data = response.roleCounts;

          // Extract counts for each role
          const totalEmployers =
            data.find((item) => item.role === "employer")?.count || 0;
          const totalApplicants =
            data.find((item) => item.role === "applicant")?.count || 0;
          const totalSellers =
            data.find((item) => item.role === "seller")?.count || 0;
          const activeJobs = response?.activeJobs;
          // Set the extracted data
          setData({
            totalEmployers,
            totalApplicants,
            totalSellers,
            activeJobs,
          });
        } else if (response.status == 400) {
          // Handle the 400 status code error if needed
        }
      } catch (error) {
        console.error(error);
        // Handle other errors
      } finally {
        // Any final logic can be placed here
      }
    }
    getDashboard();
  }, []);
  return (
    <div className="p-8">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
        <div className="dashboardsection-card flex  justify-center gap-3">
          <div className="dashboardsection-image ">
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M42 34.125V31.5H39.2424C39.0722 30.6787 38.746 29.8977 38.2817 29.1992L40.2373 27.2436L38.3814 25.3877L36.4258 27.3433C35.7273 26.879 34.9463 26.5528 34.125 26.3826V23.625H31.5V26.3826C30.6787 26.5528 29.8977 26.879 29.1992 27.3433L27.2436 25.3877L25.3877 27.2436L27.3433 29.1992C26.879 29.8977 26.5528 30.6787 26.3826 31.5H23.625V34.125H26.3826C26.5532 34.9611 26.8866 35.7381 27.3433 36.4258L25.3877 38.3814L27.2436 40.2373L29.1992 38.2817C29.8977 38.746 30.6787 39.0722 31.5 39.2424V42H34.125V39.2424C34.9463 39.0722 35.7273 38.746 36.4258 38.2817L38.3814 40.2373L40.2373 38.3814L38.2817 36.4258C38.746 35.7273 39.0722 34.9463 39.2424 34.125H42ZM32.8125 36.75C30.6416 36.75 28.875 34.9834 28.875 32.8125C28.875 30.6416 30.6416 28.875 32.8125 28.875C34.9834 28.875 36.75 30.6416 36.75 32.8125C36.75 34.9834 34.9834 36.75 32.8125 36.75ZM26.25 22.3125H15.75C15.0538 22.3125 14.3861 22.0359 13.8938 21.5437C13.4016 21.0514 13.125 20.3837 13.125 19.6875V5.25C13.125 4.55381 13.4016 3.88613 13.8938 3.39384C14.3861 2.90156 15.0538 2.625 15.75 2.625H26.25C26.9462 2.625 27.6139 2.90156 28.1062 3.39384C28.5984 3.88613 28.875 4.55381 28.875 5.25V19.6875C28.875 20.3837 28.5984 21.0514 28.1062 21.5437C27.6139 22.0359 26.9462 22.3125 26.25 22.3125ZM15.75 19.6875H26.25V5.25H15.75V19.6875Z"
                fill="#ff6800"
              />
              <path
                d="M22.3125 27.5625H10.5C9.80381 27.5625 9.13613 27.2859 8.64384 26.7937C8.15156 26.3014 7.875 25.6337 7.875 24.9375V9.1875H10.5V24.9375H22.3125V27.5625Z"
                fill="#ff6800"
              />
              <path
                d="M17.0625 32.8125H5.25C3.80231 32.8125 2.625 31.6352 2.625 30.1875V14.4375H5.25V30.1875H17.0625V32.8125Z"
                fill="#ff6800"
              />
            </svg>
          </div>
          <div>
            <h2 className="">Total Messages</h2>
            <p className="text-center ">{Data?.activeJobs}</p>
          </div>
        </div>
      </div>
      {/* <div className="bg-white p-4 rounded-lg shadow">
        <h2 className="text-lg font-bold mb-4" style={{ color: "#000" }}>
          Recent Applications
        </h2>
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={data}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      </div> */}
    </div>
  );
};

export default AdminDashboard;
