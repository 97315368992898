import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Api";
import { useHistory } from "react-router-dom";
import { getUserData } from "../../helpers/utils";
import FeatherIcon from "feather-icons-react";
const domainpath = process.env.REACT_APP_DOMAIN;

const Tickets = () => {
  const User = getUserData();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showMessages, setShowMessages] = useState(true);
  const [showNotes, setShowNotes] = useState(false);
  const history = useHistory();
  const handleDelete = async (id) => {
    const response = await api.deleteTicket(`${id}`);
    console.log(response);

    if (response.status === 200) {
      toast.success(response.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      toast.error("There was an error deleting the job!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const columns = [
    {
      name: "Ticket ID",
      selector: (row) => (
        <a
          href={`${domainpath}/dashboard/edit-ticket/${row?._id}`}
          className=" hover:underline"
          style={{ color: "#000", fontSize: "14px", fontWeight: 600 }}
        >
          {row._id}
        </a>
      ),
      sortable: true,
    },
    {
      name: "Submitted By",
      selector: (row) => row?.userId?.name,
      sortable: true,
    },
    {
      name: "Assigned To",
      selector: (row) => row?.assignedTo?.name,
      sortable: true,
    },
    {
      name: "Subject",
      selector: (row) => row.subject,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-1">
          <button
            className="ViewDetails-tickets text-white px-4 py-2 rounded-lg"
            onClick={(e) => history.push(`/dashboard/ticket/${row?._id}`)}
          >
            View Details
          </button>
          <button
            className="manage-delete-btn text-white px-6 py-2"
            onClick={() => handleDelete(row?._id)}
          >
            <FeatherIcon icon="trash" />
          </button>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data,
  };
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);

        const response = await api.getTicket();
        if (response.status === 200) {
          setData(response?.tickets);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);
  return (
    <div className="p-8">
      <div className="bg-white p-4 rounded-lg shadow">
        <div className="flex justify-between items-center py-5">
          <h2 className="text-lg font-bold mb-4" style={{ color: "#000" }}>
            Tickets
          </h2>
          <div className="">
            <a href="/dashboard/add-ticket">
              <button className="manage-edit-btn text-white px-3 py-2">
                Add Ticket
              </button>
            </a>
          </div>
        </div>

        {loading ? (
          <p>Loading...</p>
        ) : (
          <DataTableExtensions {...tableData}>
            <DataTable
              columns={columns}
              data={data}
              defaultSortField="id"
              defaultSortAsc={false}
              pagination
              highlightOnHover
              className="custom-scrollbar"
            />
          </DataTableExtensions>
        )}
      </div>{" "}
      <ToastContainer />
    </div>
  );
};

export default Tickets;
