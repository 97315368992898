// App.js
import React, { useState, useEffect } from "react";
import "../src/css/responcive.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./App.css";
import "./components/Layout/menubaar.css";
import { getUserRole } from "./helpers/utils.js";
// import Login from "../src/components/Login";
import Websitelayout from "./components/Layout/Websitelayout.js";
import SuperAdminLayout from "./components/Layout/SuperAdminLayout.js";
import ClientLayout from "./components/Layout/ClientLayout.js";
import ChatPenel from "./components/Website/Chatpanel.js";
import NotFound from "./components/Website/NotFound.js";
import PermissionDenied from "./components/Website/PermissionDenied.js";
import Chatpanel from "./components/Website/Chatpanel.js";

const App = () => {
  const userRole = getUserRole();
  const ProtectedRoute = ({ component: Component, allowedRoles, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (!userRole) {
          return <Redirect to="/" />; // Redirect if not logged in
        }
        if (allowedRoles.includes(userRole)) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/permission-denied" />;
        }
      }}
    />
  );
  return (
    <Router>
      <Switch>
        <Route path="/chatbot/:client" component={Chatpanel} />
        {/* Role-Based Routing */}
        <Route
          path="/dashboard"
          render={(props) => {
            if (userRole === "clients") {
              return <ClientLayout {...props} />;
            }
            if (userRole === "superadmin") {
              return <SuperAdminLayout {...props} />;
            }
            return <PermissionDenied />;
          }}
        />
        <Route exact path="/chat" component={ChatPenel} />
        {/* Websitelayout for all other routes */}
        <Route path="/" render={(props) => <Websitelayout {...props} />} />
        {/* Permission Denied Page */}
        <Route path="/permission-denied" component={PermissionDenied} />
        {/* Catch-all route for 404 - Page Not Found */}
        <Route path="/404" component={NotFound} />
        {/* Catch-all route for 404 - Page Not Found */}
        <Route component={() => <Redirect to="/404" />} />
      </Switch>
    </Router>
  );
};

export default App;
