import React, { useState } from "react";

const FAQPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is the purpose of this website?",
      answer:
        "This website is designed to provide users with comprehensive job search features, career resources, and a platform for job seekers to connect with employers. We aim to help individuals find the right job by offering tailored job listings, industry insights, and a simple application process to make job hunting more efficient.",
    },
    {
      question: "How can I apply for jobs?",
      answer:
        'To apply for jobs, you need to create an account on our platform. Once registered, you can upload your resume, fill out your profile, and start searching for job opportunities. When you find a job that interests you, simply click "Apply" and follow the instructions to submit your application directly to the employer. Our platform also allows you to track the status of your applications and receive notifications for updates.',
    },
    {
      question: "How can I contact customer support?",
      answer:
        'Our customer support team is available to assist you with any questions or issues you may have. You can reach them through the "Contact Us" page on our website or by emailing support@example.com. Additionally, our help center provides a variety of articles that may answer your questions. We aim to respond to all inquiries within 24 hours on weekdays.',
    },
    {
      question: "How can I reset my password?",
      answer:
        'If you have forgotten your password or would like to reset it for security reasons, you can do so by clicking the "Forgot Password" link on the login page. You will be asked to enter your registered email address, and we will send you a link to reset your password. If you do not receive the email within a few minutes, please check your spam folder.',
    },
    {
      question: "Can I apply for multiple jobs at once?",
      answer:
        "Yes, you can apply for multiple jobs simultaneously. Our platform allows you to save and manage your applications, so you can apply for different positions that match your skills and preferences. We recommend tailoring your resume and cover letter for each job application to improve your chances of getting hired.",
    },
    {
      question: "How do I update my profile?",
      answer:
        'To update your profile, simply log into your account and go to your "Profile" section. From there, you can update your personal information, career details, skills, education, and resume. We encourage you to keep your profile up to date so potential employers can see your most relevant information.',
    },
  ];

  return (
    <div className="gradient-background min-h-screen py-10">
      <div className="pt-20">
        {" "}
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
          <h1
            className="text-3xl font-semibold text-[#595959] text-center mb-8"
            style={{}}
          >
            Frequently Asked Questions
          </h1>

          {faqData.map((faq, index) => (
            <div
              key={index}
              className="faq-item border-b border-gray-300 pb-4 mb-6"
            >
              <button
                className="faq-question w-full text-left font-medium text-[#595959] hover:text-[#008596] flex justify-between items-center py-3 px-4 rounded-md transition-all duration-300 ease-in-out transform hover:scale-105"
                onClick={() => toggleFaq(index)}
              >
                <span>{faq.question}</span>
                <span className="faq-icon text-xl">
                  {activeIndex === index ? "-" : "+"}
                </span>
              </button>
              <div
                className={`faq-answer mt-4 text-[#595959] transition-all duration-500 ease-in-out transform ${
                  activeIndex === index
                    ? "max-h-[500px] opacity-100"
                    : "max-h-0 opacity-0 overflow-hidden"
                }`}
              >
                <p>{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQPage;
