import React from "react";
import logo from "../../chatbot-image/footer_logo.png"; // Make sure to update with your correct logo path
import victorImage from "../../chatbot-image/Vector.png";

const Footer = () => {
  return (
    <footer className="bg-cover bg-no-repeat bg-center  text-white">
      {/* <div className="container mx-auto pt-12 flex flex-col md:flex-row items-center justify-between newsletter-signup-section">
        <div>
          <div className="lg:w-1/2 px-10 ">
            <div className="text-left">
              <h2 className="">
                Always Available To Assist You With{" "}
                <span className="">Any Issue</span>
              </h2>
            </div>
            <div className="relative">
              <img src={victorImage}></img>
            </div>
          </div>

          <div className="lg:w-1/2">
            <div className="mt-6 md:mt-0 flex items-center">
              <label htmlFor="email" className="sr-only">
                Email Address
              </label>
              <div className="relative">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email Address"
                  className="border-2 border-dotted border-orange-500 rounded-full px-4 py-2 pr-16 text-gray-700 focus:outline-none"
                />
                <button className="absolute right-0 top-0 h-full bg-orange-500 text-white px-4 rounded-full flex items-center justify-center">
                  <i className="fas fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className=" footer-background-image">
        <div className="md:pt-40 lg:px-20 xl:px-0 md:px-4  md:p-0 p-7">
          <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 lg:gap-8 md:gap-2 gap-5">
            {/* Company Info */}
            <div className="flex flex-col items-start">
              <img src={logo} alt="Analybiz Logo" className="h-20 mb-3" />
              <p className="mb-4">
                Talkie Programmed To Generate Human-Like Responses And Provide
                Information On A Wide Range Of Topics.
              </p>
              <div className="flex space-x-4">
                <a href="#" className="text-white">
                  <i className="fab fa-facebook"></i>
                </a>
                <a href="#" className="text-white">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#" className="text-white">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>

            {/* Quick Links */}
            <div>
              <h3 className="font-bold text-xl mb-4">Quick Links</h3>
              <ul>
                <li className="flex items-center">
                  <svg
                    style={{ fill: "#ff6800" }}
                    class="fill-[] mt-1"
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>

                  <a href="/" className="text-white hover:text-orange-500">
                    Home
                  </a>
                </li>
                <li className="flex items-center">
                  <svg
                    style={{ fill: "#ff6800" }}
                    class="fill-[] mt-1"
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>
                  <a
                    href="/features"
                    className="text-white hover:text-orange-500"
                  >
                    Features
                  </a>
                </li>
                <li className="flex items-center">
                  <svg
                    style={{ fill: "#ff6800" }}
                    class="fill-[] mt-1"
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>
                  <a
                    href="/pricing"
                    className="text-white hover:text-orange-500"
                  >
                    Pricing
                  </a>
                </li>
                <li className="flex items-center">
                  <svg
                    style={{ fill: "#ff6800" }}
                    class="fill-[] mt-1"
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>
                  <a href="/blogs" className="text-white hover:text-orange-500">
                    Blogs
                  </a>
                </li>
                <li className="flex items-center">
                  <svg
                    style={{ fill: "#ff6800" }}
                    class="fill-[] mt-1"
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>
                  <a href="/demo" className="text-white hover:text-orange-500">
                    Demo
                  </a>
                </li>
                <li className="flex items-center">
                  <svg
                    style={{ fill: "#ff6800" }}
                    class="fill-[] mt-1"
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>
                  <a href="/trial" className="text-white hover:text-orange-500">
                    Trial
                  </a>
                </li>
              </ul>
            </div>

            {/* Integration Links */}
            <div>
              <h3 className="font-bold text-xl mb-4">Integration</h3>
              <ul>
                <li className="flex items-center">
                  <svg
                    style={{ fill: "#ff6800" }}
                    class="fill-[] mt-1"
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>
                  <a
                    href="/contact-us"
                    className="text-white hover:text-orange-500"
                  >
                    Contact Us
                  </a>
                </li>
                <li className="flex items-center">
                  <svg
                    style={{ fill: "#ff6800" }}
                    class="fill-[] mt-1"
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>
                  <a
                    href="/portfolio"
                    className="text-white hover:text-orange-500"
                  >
                    Portfolio
                  </a>
                </li>
                <li className="flex items-center">
                  <svg
                    style={{ fill: "#ff6800" }}
                    class="fill-[] mt-1"
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>
                  <a
                    href="/pricing-plan"
                    className="text-white hover:text-orange-500"
                  >
                    Pricing Plan
                  </a>
                </li>
                <li className="flex items-center">
                  <svg
                    style={{ fill: "#ff6800" }}
                    class="fill-[] mt-1"
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 320 512"
                  >
                    <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                  </svg>
                  <a href="/faq" className="text-white hover:text-orange-500">
                    FAQ
                  </a>
                </li>
              </ul>
            </div>

            {/* Contact Us */}
            <div>
              <h3 className="font-bold text-xl mb-4">Contact Us</h3>
              <ul>
                <li className="flex items-center space-x-2">
                  <i className="fas fa-phone-alt"></i>
                  <span>+123 456 2356</span>
                </li>
                <li className="md:flex items-center space-x-2 ">
                  <i className="fas fa-envelope"></i>
                  <span>Chathelp@gmail.com</span>
                </li>
                <li className="flex md:items-center space-x-2 md:mt-0 mt-3">
                  <i className="fas fa-map-marker-alt"></i>
                  <span>
                    1234 North Avenue Luke Lane, South Bend, IN 360001
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className="container mx-auto text-center md:mt-8 ChatAI_All">
            <p className="">
              Copyright 2024 ChatAI All Rights Reserved. | Design by{" "}
              <a href="https://ajivainfotech.com" className="text-orange-500">
                Ajiva Infotech
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
